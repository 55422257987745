<div class="container-fluid">

  <div class="row top-header">
    <div class="col-sm-8">
      <div class="input-group">
        <button type="button" class="btn filter-button" (click)="showFilters = !showFilters">
          Filter
          <i class="fa fa-filter filter-icon" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-md btn-default" style="margin:3px" (click)="exportRiders()">
          Export Riders
        </button>
      </div>
    </div>
    <div class="col-sm-4">
      <!-- search bar -->
      <div class="search-container">
        <div id="imaginary_container">
          <!-- <div class="input-group stylish-input-group">
            <input type="text" class="form-control" placeholder="Search">
            <span class="input-group-addon">
              <button type="submit">
                <span class="glyphicon glyphicon-search"></span>
              </button>
            </span>
          </div> -->
          <form [formGroup]="searchForm">
            <div class="input-group stylish-input-group">
              <input type="text" formControlName="search" class="form-control" placeholder="Search">
              <span class="input-group-addon">
                <button type="submit">
                  <span class="glyphicon glyphicon-search"></span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <app-user-filters [showFilters]=showFilters hideRatingFilter=true hideTripTakenFilter=true (onClear)="clearFilters($event)"
    (onApply)="applyFilters($event)"> </app-user-filters>

  <div class="row">
    <div>
      <!-- Table -->
      <div class="table-responsive col-sm-12">
        <table class="table table-hover table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date of registration</th>
              <th>Total Rides</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="myTable" *ngIf="isApiResponded && response.length > 0">
            <tr *ngFor="let data of response | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
              <td>{{index+i+1}}</td>
              <td>{{data.firstName | captalize}} {{data.lastName}}</td>
              <td>{{data.phone}}</td>
              <td>{{data.email?data.email:"NA"}} </td>
              <td>{{data.createdAt | date}}</td>
              <td>{{data.riderProfile?data.riderProfile.statsTripCount:0}}</td>
              <td [style.color]="getStatusTextColor(data.accountDisabled,data.accountSuspended )">{{ data.accountDisabled ? 'Blocked': (data.accountDisabled == false && data.accountSuspended == true)? 'Suspended':'Unblocked'}}
                <td>
                  <i class="fa fa-eye fa-2x" aria-hidden="true" (click)="riderDetail(data)"></i>
                  <i *ngIf="!data.accountDisabled && !data.accountSuspended" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,true)"></i>
                  <i *ngIf="!data.accountDisabled && data.accountSuspended" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,false)"></i>
                  <i *ngIf="data.accountDisabled" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,false)"></i>
                </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- pagination -->
      <div class="col-md-10 text-center" *ngIf="isApiResponded && response.length > 0">
        <div class="has-text-centered">
          <!--<div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>-->
          <pagination-controls (pageChange)="getFilterRiders($event)" id="server"></pagination-controls>
        </div>
      </div>

    </div>

    <!-- for no data-->
    <div *ngIf="isApiResponded && response.length <= 0" class="col-sm-10 text-center">
      <h2>No rider found</h2>
    </div>

  </div>
</div>