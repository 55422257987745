<!-- Create Promo code -->
<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Create Promo Code</h4>
    </bs-modal-header>
    <bs-modal-body>
        <div class="form-group row" style="display:flex;">
            <div class="col-sm-8">
                <label class="form-control-label"> Trip fare</label>
                <span *ngIf="trip"> $ {{trip.payment.amountPayable}}</span>
            </div>
        </div>
        Are you sure you want to refund trip fare to Rider?
    </bs-modal-body>
    <bs-modal-footer>
        <input type="button" class="btn btn-default" (click)="closeModal()" value="No" />
        <input type="button" class="btn btn-primary" (click)="refundPayment()" value="Yes" />
    </bs-modal-footer>
</bs-modal>