<a href="javascript:void(0)" routerLink="/login" style="color:#ff6e6e;margin-left:15px;font-size: 18px;font-weight: 400">
    Login </a>
<div style="display:flex; position:absolute; top:15px; bottom:0; right:0; left:0;">
    <div style='margin:auto;'>
        <a href="javascript:void(0)" routerLink="/tlcadminsignup" class="btn btn-signup btn-size">
            TLC Admin
        </a>
        <a href="javascript:void(0)" routerLink="/municipaladminsignup" class="btn btn-signup btn-size">
            Municipal Agent
        </a>
    </div>
</div>