<form [formGroup]="FAQForm">
  <div class="col-sm-12 tab-body">
    <!-- question field row1 -->
    <div class="row" style="margin-left:0;margin-right:0;background-color: white;">
      <div class="col-sm-8 question-area">
        <label>Question </label>
        <textarea placeholder="Enter the question here..." formControlName="quesField" maxlength="20000" class="col-sm-12 form-control"></textarea>
        <div [hidden]="(quesField.touched && quesField.valid) || quesField.untouched">
          <span class="validation" [hidden]="!quesField.hasError('required')">Required*</span>
        </div>
      </div>
      <div class="col-sm-4 question-area">
        <label>Category </label>
        <br>
        <!-- <input class="form-control" type="text" placeholder="select or enter new category"> -->
        <input class="form-control" formControlName="catField" [(ngModel)]="selectedCategory" (focus)="showMe=true" placeholder="Select or enter new category"
          (blur)="showMe=false" />
          
        <div class="over-flow-list">
          <ngui-auto-complete *ngIf="showMe" [autocomplete]="true" [show-dropdown-on-init]="true" (valueSelected)="selectedCategory = $event"
            [show-input-tag]="false" [source]="categories">
          </ngui-auto-complete>
        </div>
        <div [hidden]="(catField.touched && catField.valid) || catField.untouched">
            <span class="validation" [hidden]="!catField.hasError('required')">Required*</span>
        </div>

      </div>
    </div>

    <!-- question field row1 end -->
    


    <!-- answer field -->
    <div class="col-sm-12 question-area">
      <label>Answer </label>
      <textarea placeholder="Enter the answer here..." formControlName="ansField" maxlength="50000" class="col-sm-12 form-control"></textarea>
      <div [hidden]="(ansField.touched && ansField.valid) || ansField.untouched">
        <span class="validation" [hidden]="!ansField.hasError('required')">Required*</span>
      </div>
    </div>

    <!-- bottom of form -->
    <div class="corner-btn">
      <button class="btn btn-primary save-button" (click)="clear()">Clear</button>
      <button *ngIf="!isEdit" [disabled]="FAQForm.invalid || FAQForm.untouched" class="btn btn-primary save-button" (click)="save()">Save</button>
      <button *ngIf="isEdit" [disabled]="FAQForm.invalid || FAQForm.untouched" class="btn btn-primary save-button" (click)="edit()">Save</button>
    </div>
  </div>
</form>