<h2>Event Categories</h2>

<div class="col-sm-6">
    <span (click)="openEvents()" style="color: #8b8be6; font-size: 16px;text-decoration: underline;cursor: pointer;">
        << Back</span>
</div>
<div class="col-sm-6 text-right" style="margin-bottom: 10px;">
    <button class="btn btn-success btn-right" (click)="openEventCategoryDialogue()">Create</button>
</div>
<div class="row">
    <div class="col-sm-12">
        <div class="table-responsive col-sm-12">
            <div *ngIf="isApiResponded && categories.length > 0">
                <div *ngFor="let cat of categories | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index "
                    class="col-sm-4" style="height: 365px;
                    overflow-y: scroll; text-overflow: ellipsis;">
                    <div class="coupon col-sm-12" style="display: inline-table;margin:5px; ">
                        <div style="float:right">
                            <i *ngIf="cat.isActive" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="changeStatus(cat,false,i)"></i>
                            <i *ngIf="!cat.isActive" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeStatus(cat,true,i)"></i>
                        </div>
                        <div class="">
                            <h3 style="word-break: break-word;">{{cat.name}}</h3>
                        </div>
                        <div style="width:100%;height: 150px">
                            <img [src]="getImageUrl(cat.coverImage)" style="height: 150px;max-width:100%;">
                        </div>
                        <div class="">
                            <span style="word-break: break-word;">
                                {{cat.description}}
                            </span>
                        </div>
                        <div>
                            <div style="float: left">
                                <span><b>From</b> : {{cat.effectiveFrom| date}}</span> <br>
                                <span><b>To</b> :{{cat.effectiveUpto| date}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-10 text-center" *ngIf="isApiResponded && categories.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getEventCategories($event)" id="server"></pagination-controls>
            </div>
        </div>
    </div>
</div>
<event-categories-form #eventCatForm (callbackAddCategory)="categoryAdded($event)"></event-categories-form>