<div class="col-middle">
  <div class="text-center ">
    <h2>Forgot Password</h2>
    <p>Enter the email address associated with your account to reset your password.
    </p>
    <div class="mid_center">
      <form [formGroup]="form" (ngSubmit)="forgotPassword()">
        <div class="form-group">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Enter Email" formControlName="email"
            (focus)="emailHasfocus=true" (blur)="emailHasfocus=false"
            >

            <span class="input-group-btn">
                  <button class="btn btn-default" type="submit">Go!</button>
            </span>
          </div>
          <p *ngIf="!form.controls.email.value && !emailHasfocus && ((submittedOnce && !form.controls.email.valid)
                        || (!submittedOnce && form.controls.email.touched && !form.controls.email.valid))" class="text-left text-danger">Email is required</p>

          <p *ngIf="form.controls.email.value && !emailHasfocus && ((submittedOnce && !form.controls.email.valid)
                        || (!submittedOnce && form.controls.email.touched && !form.controls.email.valid))" class="text-left text-danger">Email is not valid</p>
        </div>
      </form>
      <a class="btn" routerLink="/login">
        <u>Back to Login</u>
      </a>
    </div>
  </div>
</div>