<h3>Messaging</h3>

<div class="col-sm-12">
  <h5 for=""> Send notification to registered users.</h5>
</div>
<div class="col-sm-6 panel panel-body" style="position: relative;display: flex">
  <div class="col-sm-6">
    <form [formGroup]="form" (ngSubmit)="sendNotification()">
      <div class="form-group">
        <textarea formControlName="message" class="form-control" name="message" id="" rows="10" placeholder="Enter message  here to send..."></textarea>
        <p *ngIf="(form.controls.message.invalid && form.controls.message.touched) || (form.controls.message.invalid && submittedOnce)"
          style="color:red">
          Message is required*</p>
      </div>
      <div class="form-group text-right">
        <div *ngIf="selectedUsers?.length <= 0;" style="display: inline-grid">
          <button class="btn btn-success">Send to all</button>
        </div>
        <input type="button" class="btn btn-danger form-group" (click)="clearMessage()" value="Clear">
      </div>
    </form>
  </div>
  <div class="col-sm-6" style="border: 1px solid #ccc;border-radius: 1px;">
    <div class="row">
      <div class="col-sm-12 form-group" style="margin-bottom: 0">
        <h4>
          <span class="label label-default">Selected users</span>
        </h4>
      </div>
      <div *ngIf="selectedUsers?.length > 0">
        <div class="col-sm-6">
          <button class="btn btn-success" (click)="sendNotification(true)">Send To Selected</button>
        </div>
        <div class="col-sm-6">
          <button class="btn btn-danger" style="float:right;right:0" (click)="selectedUsers=[]">
            Clear
          </button>
        </div>
      </div>

    </div>
    <div style="margin-top:0;padding:0">
      <hr>
    </div>
    <div *ngIf="selectedUsers?.length > 0">
      <table class="table table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of selectedUsers let i=index ">
            <td>{{index+i+1}}</td>
            <td>{{data.firstName}} {{data.lastName}}</td>
            <td>
              <i class="fa fa-minus fa-2x" aria-hidden="true" (click)="removeUser(data)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="selectedUsers?.length <= 0">
      Please search and select user to send notifications.
    </div>
  </div>
</div>
<div class="col-sm-6">
  <div class="search-container">
    <div id="imaginary_container">
      <form [formGroup]="searchForm">
        <div class="input-group stylish-input-group">
          <input type="text" formControlName="search" class="form-control" placeholder="Search">
          <span class="input-group-addon">
            <button type="submit">
              <span class="glyphicon glyphicon-search"></span>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
  <div class="table-responsive col-sm-12 panel panel-body" *ngIf="users && users.length > 0">
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>Phone Number</th>
          <th>Email</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of users let i=index ">
          <td>{{index+i+1}}</td>
          <td>{{data.firstName}} {{data.lastName}}</td>
          <td>{{data.phone}}</td>
          <td>{{data.email?data.email:"NA"}} </td>
          <td>
            <i class="fa fa-plus fa-2x" aria-hidden="true" (click)="addUsers(data)"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>