<p-tabView>
    <p-tabPanel header="Ehail Requests">
        <div class="container-fluid">
            <div class="col-sm-12" style="margin-bottom: 10px">
                <h2>
                    Ehail Requests
                </h2>
                <!-- <a href="javascript:void(0)" (click)="downloadCSV()" class="btn btn-primary" style="color:white">Export Data</a> -->
                <form [formGroup]="ehailEhailRequestForm" (submit)="onSubmit(ehailEhailRequestForm)">
                    <div class="col-sm-12">
                        <label for="effectiveUpto" class="form-control-label">Select date </label>
                    </div>
                    <div class="">
                        <div class="col-sm-3" style="display: table">
                            <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                            <p *ngIf="(!ehailEhailRequestForm.controls.dateRange.valid && ehailEhailRequestForm.controls.dateRange.touched) || (!ehailEhailRequestForm.controls.dateRange.valid && submittedOnce)"
                                class="text-left text-danger">
                                Date range is required</p>
                        </div>
                        <div class="col-sm-3">
                            <button class="btn btn-primary">Export Ehail-Requests</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="table-responsive col-sm-12">
                        <table class="table table-hover table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>App</th>
                                    <th>Trip_RequestNum</th>
                                    <th>Request_DateTime</th>
                                    <th>Req_Pickup_Lat</th>
                                    <th>Request_Pickup_Long</th>
                                    <th>Request_Pickup_Address</th>
                                    <th>Vehicle</th>
                                    <th>Hack_number</th>
                                    <th>Request_Outcome</th>
                                    <th>WAV_Request</th>
                                </tr>
                            </thead>
                            <tbody id="myTable" *ngIf="isApiResponded && ehailDataList.length > 0">
                                <tr *ngFor="let data of ehailDataList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                                    <td>{{index+i+1}}</td>
                                    <td>{{data.app}}</td>
                                    <td>{{data.tripRequestNum}}</td>
                                    <td>{{data.requestDateTime | date:'MM-dd-yyyy hh:mm:ss' }}</td>
                                    <td>{{data.reqPickupLat}} </td>
                                    <td>{{data.requestPickupLong}}</td>
                                    <td>{{data.requestPickupAddress}}</td>
                                    <td>{{data.vehicle}}</td>
                                    <td>{{data.hackNumber}}</td>
                                    <td>{{data.requestOutcome}}</td>
                                    <td>{{data.wavRequest}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-md-10 text-center" *ngIf="isApiResponded && ehailDataList.length > 0">
                    <div class="has-text-centered">
                        <pagination-controls (pageChange)="getEhailRequestData($event)" id="server"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </p-tabPanel>
    <p-tabPanel header="Ehail TripSheet">

        <app-ehail-tripsheet></app-ehail-tripsheet>
    </p-tabPanel>
    <p-tabPanel header="Ehail Flex fare export">
        <app-flex-fare></app-flex-fare>
    </p-tabPanel>
    <p-tabPanel header="Refusal Export">
        <app-refusal-requests></app-refusal-requests>
    </p-tabPanel>
    <p-tabPanel header="Yellow Taxi" >
        <app-ehail-yellow-taxi-requests></app-ehail-yellow-taxi-requests>
    </p-tabPanel>
    <p-tabPanel header="Shl log">
        <app-ehail-shl-log></app-ehail-shl-log>
    </p-tabPanel>
</p-tabView>