<div class="login col-sm-12">

    <div class="login_wrapper">
        <div class="col-sm-4" class="login-container">
            <form [formGroup]="form" (ngSubmit)="login()">
                <h1 class="text-center">
                    <img src="../../../assets/images/group.png" alt="" width="250">
                </h1>

                <div class="login_content">

                    <input type="text" class="form-control" placeholder="Email" formControlName="email" (focus)="emailHasfocus=true" (blur)="emailHasfocus=false"
                    />

                    <p *ngIf="!form.controls.email.value && !emailHasfocus && ((submittedOnce && !form.controls.email.valid)
                                    || (!submittedOnce && form.controls.email.touched && !form.controls.email.valid))" class="text-left text-danger">Email is required</p>

                    <p *ngIf="form.controls.email.value && !emailHasfocus && ((submittedOnce && !form.controls.email.valid)
                                    || (!submittedOnce && form.controls.email.touched && !form.controls.email.valid))" class="text-left text-danger">Enter a valid email </p>
                </div>

                <div class="login_content">

                    <input type="password" class="form-control" placeholder="Password" formControlName="password" (focus)="passwordHasfocus=true"
                        (blur)="passwordHasfocus=false" />

                    <p *ngIf="!form.controls.password.value && !passwordHasfocus && ((submittedOnce && !form.controls.password.valid)
                                    || (!submittedOnce && form.controls.password.touched && !form.controls.password.valid))"
                        class="text-left text-danger">
                        Password is required</p>

                    <p *ngIf="form.controls.password.value && !passwordHasfocus && ((submittedOnce && !form.controls.password.valid)
                                    || (!submittedOnce && form.controls.password.touched && !form.controls.password.valid))"
                        class="text-left text-danger">
                        Enter a valid password </p>
                </div>
                <div class="login_content">
                    <select name="role" id="" class="form-control" formControlName="role">
                        <option value="">Admin</option>
                        <option *ngFor="let r of rolesOptions" [ngValue]="r.key">
                            {{r.value}}
                        </option>
                    </select>
                </div>
                <div class="login_content">
                    <button type="submit" class="btn btn-default btn-rounded btn-lg btn-login">Sign in</button>
                </div>
                <div class="">
                    <a href="javascript:void(0);" routerLink="/landing" class="">
                        <u>Create Account</u>
                    </a>
                    <a href="javascript:void(0);" routerLink="/forgotpassword" class="forgot-pwd">
                        <u>Forgot password?</u>
                    </a>
                </div>
            </form>
        </div>
    </div>
</div>