<div class="container-fluid">
  <div class="row top-header">
    <div class="col-sm-8">
      <div class="input-group">
        <button type="button" class="btn filter-button" (click)="showFilters = !showFilters" style="margin:3px">
          Filter
          <i class="fa fa-filter filter-icon" aria-hidden="true"></i>
        </button>
        <button type="button" class="btn btn-md btn-default" style="margin:3px" (click)="createDriver()">
          Create
        </button>
        <button type="button" class="btn btn-md btn-default" style="margin:3px" (click)="exportDrivers()">
          Export drivers 
        </button>
      </div>
    </div>
    <div class="col-sm-4">
      <!-- search bar -->
      <div class="search-container">
        <div id="imaginary_container">
          <form [formGroup]="searchForm">
            <div class="input-group stylish-input-group">
              <input type="text"   formControlName="search" class="form-control" placeholder="Search" >
              <span class="input-group-addon">
                <button type="submit">
                  <span class="glyphicon glyphicon-search"></span>
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <app-user-filters [showFilters]=showFilters (onClear)="clearFilters($event)" (onApply)="applyFilters($event)">
  </app-user-filters>
  <div class="row">
    <!-- Table -->
    <div class="table-responsive col-sm-12">
      <table class="table table-hover table-striped table-bordered">
        <thead class="table-head">
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Date of Registration</th>
            <th>Date of Approval</th>
            <th>Rating</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="myTable" *ngIf="isApiResponded && verifiedDriverResponse.length > 0">
          <tr *ngFor="let data of verifiedDriverResponse | paginate: { id: 'server' ,itemsPerPage: pagination.limit, currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
            <td>{{index+i+1}}</td>
            <td>{{data.firstName | captalize}} {{data.lastName}}</td>
            <td>{{data.phone}}</td>
            <td>{{data.email?data.email:"NA"}} </td>
            <td>{{data.createdAt | date}}</td>
            <td>{{data.driverProfile.approvedAt | date}}</td>
            <td>{{data.driverProfile.averageRating | number:'1.0-1'}}</td>
            <td [style.color]="getStatusTextColor(data.accountDisabled,data.accountSuspended )">{{ data.accountDisabled ? 'Blocked': (data.accountDisabled == false && data.accountSuspended == true)? 'Suspended':'Unblocked'}}
            </td>
            <td>
              <i class="fa fa-eye fa-2x" aria-hidden="true" (click)="verifiedDriverDetail(data)"></i>
              <i *ngIf="!data.accountDisabled && !data.accountSuspended" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,true)"></i>
              <i *ngIf="!data.accountDisabled && data.accountSuspended" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,false)"></i>
              <i *ngIf="data.accountDisabled" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeAccountStatus(data,false)"></i>
              <i class="fa fa-pencil fa-2x" aria-hidden="true" (click)="editUser(data._id)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- pagination -->
    <div class="col-sm-10 text-center" *ngIf="isApiResponded &&verifiedDriverResponse.length > 0">
      <div class="has-text-centered">
        <pagination-controls (pageChange)="getVerifiedDrivers($event)" id="server"></pagination-controls>
      </div>
    </div>

  </div>

  <!-- for no data-->
  <div *ngIf="isApiResponded && verifiedDriverResponse.length <= 0" class="col-sm-10 text-center">
    <h2>No driver found</h2>
  </div>

</div>
<apply-flat-trips-form  #flatTripCodeForm (callbackAppliedFlatTrips)="appliedFlatRide($event)"></apply-flat-trips-form>