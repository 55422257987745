<div style="width: 620px;padding: 20px; margin:0 auto;" *ngIf="trip">
  <!-- header -->
  <table style="width: 100%;height: 120px;border-collapse: collapse">
    <tbody>
      <tr bgcolor="#ff6e6e ">
        <td style="width: 25%;padding: 0px;margin: 0px;color: #ffffff;text-align: center;font-size: 20px;">Receipt</td>
        <td style="width: 50%;text-align: center;margin: 0px;padding: 0px;margin: 0px; ">
          <img src="https://marketing-image-production.s3.amazonaws.com/uploads/0027effcfb93027f9518f74629836d82908ef91c4ca31721fec033b1ce7f557de3fd92e02530acaae664030d52601e7cc3a3de320196d8c75d09d1cd2577b649.png ">
        </td>
        <td style="width: 25%;color: #ffffff;text-align: center;padding: 0px;margin: 0px; ">
          <div style="display: inline-block; text-align: end; ">
            <div style="font-size: 26px ">$ {{total}}</div>
            <div style="margin-top:10px; font-size: 16px; opacity: 0.9; margin-right: 10px; ">Total Fare</div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- trip-route-map -->
  <div style="height: 300px;margin-top: 12px;">
    <img style="width: 100%;height: 100%;object-fit: cover;" [src]="routeUrl">
  </div>

  <!-- trip detail [time miles date] -->
  <div style="padding:16px 16px 0px 16px">
    <table style="width:100%; border-bottom: 2px inset #ccc; ">
      <tbody>
        <tr>
          <td style="width:32%">
            <div style="margin: 12px 0px;">
              <img style="float: left; padding-left:24px; width:12px; height:18px " src="https://marketing-image-production.s3.amazonaws.com/uploads/836d9330fdbafccd0284c89abc3ed84ac893b56cab20688641d2e829114e108724e12837208fba69db1c6101e2c3c74014730acc95393d1d7e30148f54033d8c.png">
              <div style="overflow: hidden; padding-left:10px; padding-right:10px; padding-top: 1px;">
                &nbsp;{{trip.duration}}&nbsp;
              </div>
            </div>
          </td>
          <td style="width:35%">
            <div style="width: 100%; border-left: 2px solid #ccc; border-right: 2px solid #ccc;margin: 12px 0px; ">
              <div style="margin: 0 auto; display: table;">
                <img style="float: left; padding-left:14px; width:18px; height:18px " src="https://marketing-image-production.s3.amazonaws.com/uploads/168a2ac814e72d811597ec5964ab4c637e9ba70bf044c68b67c3bc6b98b42bb9d3a458f119b70d5971fc698c0e85d33f2108e497297b16c3fd49811468e56f89.png">
                <div style=" overflow: hidden; padding-left:10px;  padding-right:10px; padding-top: 1px;">
                  {{trip.distance}}&nbsp;miles
                </div>
              </div>
            </div>
          </td>
          <td style="width:32%">
            <div style="margin: 12px 0px;">
              <img style="float: left; padding-left:24px; width:18px; height:18px " src="https://marketing-image-production.s3.amazonaws.com/uploads/46d3e81ec7f756eabf10c1694907ecdbe535008e9377da0f48aa3ba1194c22b5f39e90240ac033204913ecbad4e0ccd1069acc83d5a26a4e619b96f52e4050d4.png">
              <div style="overflow: hidden; padding-left:10px;padding-top: 1px;">
                &nbsp;{{date}}
              </div>
            </div>
          </td>
        </tr>

      </tbody>
    </table>

    <!-- trip locations [source, destination] -->
    <div style="overflow: auto; padding-top: 20px; padding-bottom: 20px; border-bottom: 2px inset #ccc; ">
      <div style="float:left; width:50%; border-right: 2px solid #ccc; padding:0px; margin: 0px; ">
        <div style=" position: relative;float: left; margin-left: 30px;">
          <img style="width:24px; height:28px;" src="https://marketing-image-production.s3.amazonaws.com/uploads/42223d5b67decc8fcc658cf7d5bc818f5dc3aa656c7696ee56d4d9833b4def6e5c136ef7807ed9314915bb88a3073915caabcd4fa878bb0aa9b766faadda92ce.png">
        </div>
        <div style="overflow: hidden; padding-left: 12px; padding-right: 10px; font-size: 1.2em; margin-top: 2px; text-decoration:none ">
          {{trip.sourceAddress}} </div>
      </div>

      <div style="overflow: hidden; padding:0px; margin: 0px;margin-left: 30px;">
        <div style="position: relative;float: left; margin-left: 30px;">
          <img style="width:24px; height:28px;" src="https://marketing-image-production.s3.amazonaws.com/uploads/1f031d16545357f847a1289956f954226110a34cb7b6b8766b844877d0b19839d4053b39651139d35fe7642f14f01ecda8c845500025f0d2e2e9bd48eb078f1e.png">
        </div>
        <div style="overflow: hidden; padding-left: 12px; padding-right: 10px; font-size: 1.2em; margin-top: 2px; text-decoration:none ">
          {{trip.destAddress}} </div>
      </div>
    </div>

    <div style="overflow: auto; padding-top: 20px; padding-bottom: 20px; border-bottom: 2px inset #ccc; " *ngIf="trip.stops[0]">
      <div style="float:left; width:100%; padding:0px; margin: 0px; ">
        <div style=" position: relative;float: left; margin-left: 30px;">
          <span style="display: inline-block; font-weight: 800; font-size: 1.2em;margin-top: 1px">Stop:</span>
        </div>
        <div style="overflow: hidden; padding-left: 12px; padding-right: 10px; font-size: 1.2em; margin-top: 2px; text-decoration:none ">
          {{trip.stops[0].address}} </div>
      </div>
    </div>

    <!-- Image of a user -->
    <div style="overflow: auto; width:100%; padding:26px 0px 8px 0px; border-bottom: 2px solid #ccc; ">

      <div style="font-size: 1.2em; color:#a6a6a6;">
        Driver Details
      </div>

      <div style="width:49%; overflow:auto; display:inline-block; ">
        <div style="padding-left: 10px;">
          <div style="float:left;">
            <div [style.background-image]='profileImageId' style="width:70px; overflow: hidden; height:70px; margin: 10px 0px; border-radius: 50%; background-size: contain">
            </div>
          </div>

          <div style="overflow: hidden; padding:22px 4px 4px 16px;">
            <div style="padding: 0px 6px 8px 6px;"> {{carType}} </div>
            <div style="padding: 8px 6px 8px 6px; color:#a6a6a6"> {{driverName}} </div>
          </div>
        </div>
      </div>

      <div style="float:right; width: 35%; padding:22px 10px 4px 16px; text-align: right">
        <div style="display: table; float: right;">
          <div style="  display: table-cell;vertical-align: middle;">
            <img style=" margin:0 auto; width:20px; height:20px " src="https://marketing-image-production.s3.amazonaws.com/uploads/390d9dddc3a6e1d384516b8d7c216d2ff4671663e8d256c82aecf0979a5cc7fb28029dcc0552af7be12e619d43b0b1d11a5c4a543f0da86644b096552a47583c.png ">
          </div>
          <div style="  display: table-cell;vertical-align: middle;">
            <span style="display:inline-block;padding: 0px 0px 0px 6px;"> {{rating}} </span>
          </div>
        </div>
        <div style="clear: both; padding: 12px 0px 8px 6px; color:#a6a6a6">{{serviceName}}</div>
      </div>
    </div>
  </div>

  <!-- payment detail -->
  <div style="padding:0px 16px 16px 16px; ">
    <div style="padding:26px 0px 8px 0px;">
      <div style="font-size: 1.2em; color:#a6a6a6; margin-bottom: 10px;">Fare Breakup</div>
      <div style="border-radius:10px;border: 2px solid #ccc; ">
        <table style="width:100%; height: 120px;  padding:6px;  border-collapse:collapse; border-style: hidden; ">
          <tbody>
            <tr>
              <td style="padding: 10px; text-align: left; font-size:16px; ">Ride Fare</td>
              <td style="padding: 10px; text-align: right; font-size:16px "><span>$ {{fare}} </span></td>
            </tr>
            <tr>
              <td style="padding: 10px; text-align: left; font-size:16px; border-color:#ff6e6e ">Tip ({{tipPercent}}%)</td>
              <td style="padding: 10px; text-align: right; font-size:16px "><span>$ {{tipValue}} </span></td>
            </tr>
            <tr>
              <td style="padding:0px">
                <hr style="margin:2px 0px;">
              </td>
              <td style="padding:0px">
                <hr style="margin:2px 0px;">
              </td>
            </tr>
            <tr>
              <td style="padding: 16px 16px 16px 10px; text-align: left; font-size:20px; border-color:#ff6e6e ">Total Bill</td>
              <td style="padding: 16px 10px 16px 16px; text-align: right; font-size:20px "><span>$ {{total}} </span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>