<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group panel panel-default">
        <form [formGroup]="createUserForm" (submit)="onSubmit(createUserForm)">
          <div class="panel-body">
            <h2>
              <span *ngIf="editMode;">Update</span>
              <span *ngIf="!editMode;">Create</span> Driver </h2>
            <!-- Basic Info -->
            <div class="panel panel-default">
              <div class="panel-body">
                <h3>Basic Info</h3>
                <div class="row-element">
                  <!-- first name  -->
                  <div class="col-sm-6">
                    <span for="">First name</span>
                    <input type="text" class="form-control" placeholder="First name" formControlName="firstName" />
                    <p *ngIf="(!createUserForm.controls.firstName.valid && createUserForm.controls.firstName.touched) || (!createUserForm.controls.firstName.valid && submittedOnce)"
                      class="text-left text-danger">
                      First name is required</p>
                  </div>
                  <!-- Last name -->
                  <div class="col-sm-6">
                    <span for="">Last name</span>
                    <input type="text" class="form-control" placeholder="Last name" formControlName="lastName" />
                  </div>
                </div>

                <div class="row-element">
                  <!-- Email -->
                  <div class="col-sm-6">
                    <span for="">Email </span>
                    <input type="text" class="form-control" placeholder="Email" formControlName="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                    />
                    <p *ngIf="createUserForm.controls.email.invalid && createUserForm.controls.email.dirty && createUserForm.controls.email.value"
                      class="text-left text-danger">
                      Email not valid </p>
                  </div>
                  <!-- Password -->
                  <div class="col-sm-6" *ngIf="!editMode;">
                    <input type="password" class="form-control" placeholder="Password" formControlName="password" />
                    <p *ngIf="(createUserForm.controls.password.errors?.required && createUserForm.controls.password.touched) ||(createUserForm.controls.password.errors?.required &&  submittedOnce)"
                      class="text-left text-danger">
                      Password is required</p>
                    <p *ngIf="createUserForm.controls.password.invalid && createUserForm.controls.password.dirty  && createUserForm.controls.password.value"
                      class="text-left text-danger">
                      Password should be 6 to 25 characters .
                    </p>
                  </div>
                </div>
                <div class="row-element">
                  <!-- country code -->
                  <div class="col-sm-6">
                    <span for="">Country Code</span>
                    <input type="text" class="form-control" placeholder="Country code, Ex: US" formControlName="countryCode" />
                    <p *ngIf="(createUserForm.controls.countryCode.invalid && createUserForm.controls.countryCode.touched)|| (createUserForm.controls.countryCode.invalid && submittedOnce)"
                      class="text-left text-danger">
                      CountryCode is requred.</p>
                  </div>
                  <div class="col-sm-6">
                    <span for="">CountryCode Ext </span>
                    <div *ngIf="editMode" class="form-control">
                      <span>{{createUserForm.controls.countryCodeExt.value}}</span>
                    </div>
                    <input *ngIf="!editMode" type="text" class="form-control" placeholder="Country Code extention Ex: 1" formControlName="countryCodeExt"
                      pattern="[0-9]+" />
                    <p *ngIf="(createUserForm.controls.countryCodeExt.errors?.required && createUserForm.controls.countryCodeExt.touched)|| (createUserForm.controls.countryCodeExt.errors?.required && submittedOnce)"
                      class="text-left text-danger">
                      CountryCodeExt is required</p>
                    <p *ngIf="createUserForm.controls.countryCodeExt.invalid && createUserForm.controls.countryCodeExt.dirty  && createUserForm.controls.countryCodeExt.value"
                      class="text-left text-danger">
                      CountryCodeExt is not Valid, should be valid CountryCodeExt number.
                    </p>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6">
                    <span>Phone</span>
                    <div *ngIf="editMode" class="form-control">
                        <span>{{createUserForm.controls.phone.value}}</span>
                      </div>
                    <input *ngIf="!editMode" type="text" class="form-control" placeholder="Phone" formControlName="phone" pattern="[0-9]{10,}" />

                    <p *ngIf="(createUserForm.controls.phone.errors?.required && createUserForm.controls.phone.touched)|| (createUserForm.controls.phone.errors?.required && submittedOnce)"
                      class="text-left text-danger">
                      Phone is required</p>
                    <p *ngIf="createUserForm.controls.phone.invalid && createUserForm.controls.phone.dirty  && createUserForm.controls.phone.value"
                      class="text-left text-danger">
                      Phone is not Valid, should be 10 digit phone number.
                    </p>
                  </div>

                  <div class="col-sm-6" style="padding-left: 0;">
                    <div class="col-sm-6">
                      <span for="phoneVerified">Phone Verified </span>
                    </div>
                    <!-- checkbox validation -->
                    <div class="col-sm-6 text-right">
                      <input type="checkbox" class="" formControlName="phoneVerified" name="phoneVerified">
                      <p *ngIf="(createUserForm.controls.phoneVerified.invalid && createUserForm.controls.phoneVerified.touched) || (createUserForm.controls.phoneVerified.invalid && submittedOnce) || (!createUserForm.controls.phoneVerified.value && submittedOnce)"
                        class="text-left text-danger">
                        Phone verification is required</p>
                    </div>
                  </div>
                  <div class="row-element">
                    <div class="col-sm-6">
                      <div class="col-sm-3" style="padding: 0;">
                        <span>Profile Image </span>
                      </div>
                      <div class="col-sm-9" style="padding: 0;">
                        <div *ngIf="editMode && profileImgUrl">
                          <img src="{{profileImgUrl}}" height="100" width="100">
                        </div>
                        <div *ngIf="!editMode">
                          <image-upload [buttonCaption]="'Select profile image'" [max]="1" [extensions]="['jpg','png','gif']" [url]="fileUploadUrl"
                            [dropBoxMessage]="'Drop your image here!'" (removed)="onRemoveProfileImage($event)" (uploadFinished)="onProfileImageUploadFinished($event)"></image-upload>
                        </div>

                        <p *ngIf="(createUserForm.controls.profileImageFileId.errors?.required && createUserForm.controls.profileImageFileId.touched)|| (createUserForm.controls.profileImageFileId.invalid && submittedOnce)"
                          class="text-left text-danger">
                          ProfileImage is required</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Driver Docs -->
            <div class="panel panel-default" formGroupName="driverDocs">
              <div class="panel-body" formGroupName="insuranceDoc">
                <!-- Insurance doc -->
                <h4>Insurance doc</h4>
                <div class="row-element">
                  <div class="col-sm-12">
                    <div *ngIf="editMode && insuranceDocImgUrls.length>0">
                      <img *ngFor="let img of insuranceDocImgUrls" src="{{img}}" height="100" width="100">
                    </div>
                    <div *ngIf="!editMode">
                      <image-upload [buttonCaption]="'Select documents'" [url]="fileUploadUrl" [dropBoxMessage]="'Drop documents here!'" (removed)="onRemovedInsuranceDoc($event)"
                        [extensions]="['jpg','png','gif']" (uploadFinished)="onInsuranceDocUploadFinish($event)"></image-upload>
                    </div>


                    <p *ngIf="(!createUserForm.controls.driverDocs.controls.insuranceDoc.controls.imageIds.valid && createUserForm.controls.driverDocs.controls.insuranceDoc.controls.imageIds.touched) ||  (!createUserForm.controls.driverDocs.controls.insuranceDoc.controls.imageIds.valid &&  submittedOnce)"
                      class="text-left text-danger">
                      Vehicle documents are required</p>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6" style="padding-left: 0;">
                    <div class="col-sm-6">
                      <span for="approved">Approved </span>
                    </div>
                    <!-- checkbox validation -->
                    <div class="col-sm-6 text-right">
                      <input type="checkbox" class="" formControlName="approved" name="approved">
                      <p *ngIf="(createUserForm.controls.driverDocs.controls.insuranceDoc.controls.approved.invalid && createUserForm.controls.driverDocs.controls.insuranceDoc.controls.approved.touched ) || (createUserForm.controls.driverDocs.controls.insuranceDoc.controls.approved.invalid && submittedOnce) || (!createUserForm.controls.driverDocs.controls.insuranceDoc.controls.approved.value && submittedOnce)"
                        class="text-left text-danger">
                        InsuranceDoc approval is required</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel-body" formGroupName="vehicleDoc">
                <h4>Vehicle info</h4>
                <div class="row-element">
                  <div class="col-sm-6" style="padding-left: 0;">
                    <div class="col-sm-6">
                      <span for="approved">Approved </span>
                    </div>
                    <div class="col-sm-6 text-right">
                      <input type="checkbox" class="" formControlName="approved" name="approved">
                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.approved.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.approved.touched) ||(createUserForm.controls.driverDocs.controls.vehicleDoc.controls.approved.invalid && submittedOnce) || (createUserForm.controls.driverDocs.controls.vehicleDoc.controls.approved.invalid && submittedOnce)"
                        class="text-left text-danger">
                        Vehicle approval is required</p>
                    </div>
                  </div>
                  <div class="col-sm-6" style="padding-left: 0;">
                    <div class="col-sm-3" style="padding: 0;">
                      <span>Vehicle Image </span>
                    </div>
                    <!-- vehicle image imageId -->
                    <div class="col-sm-9" style="padding: 0;">
                      <div *ngIf="editMode && vehicleDocImgUrl">
                        <img src="{{vehicleDocImgUrl}}" height="100" width="100">

                      </div>
                      <div *ngIf="!editMode">
                        <image-upload [buttonCaption]="'Select vehicle image'" [url]="fileUploadUrl" [max]="1" [extensions]="['jpg','png','gif']"
                          [dropBoxMessage]="'Drop your image here!'" (removed)="onRemoveVehicleImage($event)" (uploadFinished)="onVehicleImageUploadFinish($event)"></image-upload>
                      </div>

                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.imageId.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.imageId.touched) ||  (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.imageId.valid && submittedOnce)"
                        class="text-left text-danger">
                        Vehicle image is required</p>
                    </div>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6">
                    <div class="col-sm-6">
                      <span> Year </span>
                    </div>
                    <div class="col-sm-6" style="padding: 0;">
                      <select class="form-control" formControlName="year" name="year" style="margin-left:5px" (change)="onYearSelect($event.target.value)">
                        <option *ngFor="let year of makeYears" value="{{year}}">{{year}}</option>
                      </select>
                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.year.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.year.touched) || (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.year.valid && submittedOnce)"
                        class="text-left text-danger">
                        Year is required</p>
                    </div>
                  </div>
                  <div class="row col-sm-6">
                    <div class="col-sm-6">
                      <span> Make </span>
                    </div>
                    <div class="col-sm-6" style="padding: 0;">
                      <select class="form-control" formControlName="make" name="make" style="margin-left:5px" (change)="onMakeSelected($event.target.value)">
                        <option *ngFor="let make of makes" value="{{make.make_id}}">{{make.make_display}}</option>
                      </select>
                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.make.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.make.touched) || (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.make.valid && submittedOnce)"
                        class="text-left text-danger">
                        Make is required</p>
                    </div>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6">
                    <div class="col-sm-6">
                      <span> Model </span>
                    </div>
                    <div class="col-sm-6" style="padding: 0;">
                      <select class="form-control" formControlName="model" name="model" style="margin-left:5px">
                        <option *ngFor="let m of models" value="{{m.model_name}}">{{m.model_name}}</option>
                      </select>
                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.model.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.model.touched) || (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.model.valid && submittedOnce)"
                        class="text-left text-danger">
                        Model is required</p>
                    </div>
                  </div>
                  <div class="row col-sm-6">
                    <div class="col-sm-6">
                      <span> Car Type </span>
                    </div>
                    <div class="col-sm-6" style="padding: 0;">
                      <select class="form-control" formControlName="carType" name="carType" style="margin-left:5px">
                        <option *ngFor="let car of allVehicleTypes" value="{{car._id}}">{{car._id}}</option>
                      </select>
                      <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carType.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carType.touched) || (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carType.valid && submittedOnce)"
                        class="text-left text-danger">
                        Car type is required</p>
                    </div>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6">
                    <span>Company</span>
                    <input type="text" class="form-control" placeholder="Company Name" formControlName="companyName" />
                    <p *ngIf="(!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.companyName.valid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.companyName.touched) || (!createUserForm.controls.driverDocs.controls.vehicleDoc.controls.companyName.valid && submittedOnce)"
                      class="text-left text-danger">
                      Company Name is required</p>
                  </div>
                  <div class="col-sm-6">
                    <span>Car plate Number</span>
                    <input type="text" class="form-control" placeholder="Car Plate Number" formControlName="carPlateNumber" pattern="[0-9]+"
                    />
                    <p *ngIf="(createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.errors?.required && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.touched)|| (createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.errors?.required && submittedOnce)"
                      class="text-left text-danger">
                      CarPlate Number is required</p>
                    <p *ngIf="createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.invalid && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.dirty  && createUserForm.controls.driverDocs.controls.vehicleDoc.controls.carPlateNumber.value"
                      class="text-left text-danger">
                      CarPlate Number is not Valid, should be number.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!--  Driver Profile section-->
            <div class="panel panel-default">
              <div class="panel-body" formGroupName="driverProfile">
                <h3>Driver Profile</h3>
                <div class="row-element">
                  <div class="col-sm-6">
                    <span>Ratio</span>
                    <input type="text" class="form-control" placeholder="Ratio" formControlName="ratio" />
                    <div *ngIf="createUserForm.controls.driverProfile.controls.ratio.invalid">
                      <p *ngIf="(createUserForm.controls.driverProfile.controls.ratio.touched &&  !createUserForm.controls.driverProfile.controls.ratio.value)|| (createUserForm.controls.driverProfile.controls.ratio.invalid && submittedOnce)"
                        class="text-left text-danger">
                        Ratio is required</p>
                      <p class="text-left text-danger" *ngIf="createUserForm.controls.driverProfile.controls.ratio.errors.min ">Ratio is not valid, Should not be less than 0.</p>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <span>Ongoing rate</span>
                    <input type="number" class="form-control" placeholder="Ongoing rate" formControlName="ongoingRate" />
                    <div *ngIf="createUserForm.controls.driverProfile.controls.ongoingRate.invalid">
                      <p *ngIf="(createUserForm.controls.driverProfile.controls.ongoingRate.touched && !createUserForm.controls.driverProfile.controls.ongoingRate.value) || submittedOnce"
                        class="text-left text-danger">
                        Ongoing rate is required</p>
                      <p class="text-left text-danger" *ngIf="createUserForm.controls.driverProfile.controls.ongoingRate.errors.min ">Ongoing rate is not valid, Should not be less than 0.</p>
                    </div>
                  </div>
                </div>
                <div class="row-element">
                  <div class="col-sm-6" style="padding-left: 0;">
                    <div class="col-sm-6">
                      <span for="aggreeTermsAndConditions">Terms and Condition </span>
                    </div>
                    <div class="col-sm-6 text-right">
                      <input type="checkbox" class="" formControlName="aggreeTermsAndConditions" name="aggreeTermsAndConditions">
                      <p *ngIf="(createUserForm.controls.driverProfile.controls.aggreeTermsAndConditions.invalid && createUserForm.controls.driverProfile.controls.aggreeTermsAndConditions.touched) || (createUserForm.controls.driverProfile.controls.aggreeTermsAndConditions.invalid && submittedOnce)"
                        class="text-left text-danger">
                        Agree terms and condition.</p>
                    </div>
                  </div>
                  <div class="col-sm-6" style="padding-left: 0;">

                    <div class="col-sm-6">
                      <span for="approved">Approved </span>
                    </div>
                    <div class="col-sm-6 text-right">
                      <input type="checkbox" class="" formControlName="approved" name="approved">
                      <p *ngIf="(createUserForm.controls.driverProfile.controls.approved.invalid && createUserForm.controls.driverProfile.controls.approved.touched ) || (createUserForm.controls.driverProfile.controls.approved.invalid && submittedOnce) || (!createUserForm.controls.driverProfile.controls.approved.value && submittedOnce)"
                        class="text-left text-danger">
                        Approved required</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Driver Profile -->

            <!-- Start Stripe Profile -->
            <!-- <div class="panel panel-default" formGroupName="stripeProfile">
              <div class="panel-body">
                <h3>Stripe Info</h3>
                <div class="row-element">
                  <div class="col-sm-6">
                    <input type="text" class="form-control" placeholder="Stripe account id" formControlName="stripeAccountId" />
                    <p *ngIf="(createUserForm.controls.stripeProfile.controls.stripeAccountId.errors?.required && createUserForm.controls.stripeProfile.controls.stripeAccountId.touched) || (createUserForm.controls.stripeProfile.controls.stripeAccountId.errors?.required &&  submittedOnce)"
                      class="text-left text-danger">
                      StripeAccountId is required</p>
                  </div>
                </div>
              </div>
            </div> -->
            <!-- End Stripe Profile -->
            <div class="text-right">
              <button *ngIf="!editMode" class="btn btn-default" type="button" (click)="clearForm()">Clear</button>
              <button *ngIf="editMode" class="btn btn-default" type="button" (click)="cancelUpdate()">Back</button>
              <button class="btn btn-success" type="submit">
                <span *ngIf="!editMode">Save</span>
                <span *ngIf="editMode">Update</span>
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>
</div>