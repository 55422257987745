<div class="reset" *ngIf="isDataAvailable">
  <div class="reset_wrapper">
    <form [formGroup]="form" (ngSubmit)="resetPassword()">
      <h1 class="text-center"><img src="../../../assets/images/group.png" alt="" width="250"></h1>

      <div class="reset_content">
        <input type="password" class="form-control" placeholder="Password" formControlName="newPassword" (focus)="passwordHasfocus=true"
          (blur)="passwordHasfocus=false" />

        <p *ngIf="!form.controls.newPassword.value && !passwordHasfocus && ((submittedOnce && !form.controls.newPassword.valid)
                        || (!submittedOnce && form.controls.newPassword.touched && !form.controls.newPassword.valid))" class="text-left text-danger">
          Password is required</p>

        <p *ngIf="form.controls.newPassword.value && !passwordHasfocus && ((submittedOnce && !form.controls.newPassword.valid)
                        || (!submittedOnce && form.controls.newPassword.touched && !form.controls.newPassword.valid))" class="text-left text-danger">
          Enter a valid password </p>
      </div>

      <div class="reset_content">
        <input type="password" class="form-control" placeholder="Confirm Password" formControlName="confirmPassword" (focus)="confirmPasswordHasfocus=true"
          (blur)="confirmPasswordHasfocus=false" />

        <p *ngIf="!form.controls.confirmPassword.value && !confirmPasswordHasfocus && ((submittedOnce && !form.controls.confirmPassword.valid)
                        || (!submittedOnce && form.controls.confirmPassword.touched && !form.controls.confirmPassword.valid))"
          class="text-left text-danger">
          Confirm password is required</p>

        <p class="text-left text-danger" *ngIf="form.controls.confirmPassword.value &&!confirmPasswordHasfocus && form.controls.confirmPassword.errors?.MatchPassword">New Password and confirm password must be same
        </p>
      </div>

      <div>
        <button type="submit" class="btn btn-default btn-rounded btn-block">Reset</button>
      </div>

    </form>
  </div>
</div>