<div class="container-fluid">
<div class="col-sm-2 left_col">
    <side-menu>
    </side-menu>
</div>
<div class="col-sm-10" style="text-align: right;float: right">
    <a class="btn btn-danger" (click)="logout()">Logout</a>
</div>
<h2>
    Municipality dashboard
</h2>
</div>