<!-- Create Flat Trip code -->
<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Create Flat Trip code</h4>
    </bs-modal-header>
    <form [formGroup]="flatTripsForm" (submit)="onSubmit(flatTripsForm)">
        <bs-modal-body>
            <div class="form-group row" style="display:flex;">
                <div class="col-sm-6">
                    <label class="form-control-label">Code</label>
                    <input type="text" name="code" class="form-control" placeholder="Code" formControlName="code"  uppercased>
                    <p *ngIf="(!flatTripsForm.controls.code.valid && flatTripsForm.controls.code.touched) || (!flatTripsForm.controls.code.valid && submittedOnce)"
                class="text-left text-danger">
                Code is required</p>
                </div>
                <div class="col-sm-6">
                    <label class="form-control-label">Description</label>
                    <input type="text" name="description" class="form-control" placeholder="Description" formControlName="description">
                    <p *ngIf="(!flatTripsForm.controls.description.valid && flatTripsForm.controls.description.touched) || (!flatTripsForm.controls.description.valid && submittedOnce)"
                class="text-left text-danger">
                Description is required</p>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label class="form-control-label">Value</label>
                    <input type="number" name="" class="form-control" placeholder="Value" formControlName="value">
                    <p *ngIf="(!flatTripsForm.controls.value.valid && flatTripsForm.controls.value.touched) || (!flatTripsForm.controls.value.valid && submittedOnce)"
                        class="text-left text-danger">
                        Value is required</p>
                </div>
                <div class="col-sm-6">
                    <label class="form-control-label">Type </label>
                    <div class="radio">
                        <label class="radio-inline">
                            <input type="radio" name="type" formControlName="type" value="$"> $</label>
                        <label class="radio-inline">
                            <input type="radio" name="type" formControlName="type" value="%"> %</label>
                    </div>
                    <p *ngIf="(!flatTripsForm.controls.type.valid && flatTripsForm.controls.type.touched) || (!flatTripsForm.controls.type.valid && submittedOnce)"
                        class="text-left text-danger">
                        Type is required</p>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label class="form-control-label">Maximum utilization</label>
                    <input type="number" name="count" class="form-control" formControlName="count" placeholder="Ex- 50 times">
                    <p *ngIf="(!flatTripsForm.controls.count.valid && flatTripsForm.controls.count.touched) || (!flatTripsForm.controls.count.valid && submittedOnce)"
                        class="text-left text-danger">
                        Use count is required</p>
                </div>
                <div class="col-sm-6">
                    <label for="effectiveUpto" class="form-control-label">Select Effective From-Upto </label>
                    <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                    <p *ngIf="(!flatTripsForm.controls.dateRange.valid && flatTripsForm.controls.dateRange.touched) || (!flatTripsForm.controls.dateRange.valid && submittedOnce)"
                        class="text-left text-danger">
                        Date range is required</p>
                </div>
            </div>
        </bs-modal-body>
        <bs-modal-footer>
            <input type="button" class="btn btn-default" (click)="closeModal()" value="Cancel" />
            <input type="submit" class="btn btn-primary" value="Create" />
        </bs-modal-footer>
    </form>
</bs-modal>