<div class="container-fluid">
  <div class="row">

    <div class="col-sm-12 form-body">

      <form [formGroup]="changePasswordForm">
        <div class="col-sm-8 ">

          <div class="form-group">
            <label class="control-label col-sm-4">Old Password:</label>
            <div class="col-sm-7">
              <input type="password" class="form-control" placeholder="Old Password" formControlName="oldPassword" (focus)="oldPasswordHasfocus=true"
                (blur)="oldPasswordHasfocus=false" />

              <p *ngIf="!oldPassword.value && !oldPasswordHasfocus && ((submittedOnce && !oldPassword.valid)
                        || (!submittedOnce && oldPassword.touched && !oldPassword.valid))" class="text-left text-danger">
                Password is required</p>

              <p *ngIf="oldPassword.value && !oldPasswordHasfocus && ((submittedOnce && !oldPassword.valid)
                        || (!submittedOnce && oldPassword.touched && !oldPassword.valid))" class="text-left text-danger">
                Enter a valid password </p>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label col-sm-4">New Password:</label>
            <div class="col-sm-7">
              <input type="password" class="form-control" placeholder="New Password" formControlName="newPassword" (focus)="newPasswordHasfocus=true"
                (blur)="newPasswordHasfocus=false" />

              <p *ngIf="!newPassword.value && !newPasswordHasfocus && ((submittedOnce && !newPassword.valid)
                        || (!submittedOnce && newPassword.touched && !newPassword.valid))" class="text-left text-danger">
                Password is required</p>

              <p *ngIf="newPassword.value && !newPasswordHasfocus && ((submittedOnce && !newPassword.valid)
                        || (!submittedOnce && newPassword.touched && !newPassword.valid))" class="text-left text-danger">
                Enter a valid password </p>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label col-sm-4">Retyped New Password:</label>
            <div class="col-sm-7">
              <input type="password" class="form-control" placeholder="Retype New Password" formControlName="retypeNewPassword" (focus)="retypePasswordHasfocus=true"
                (blur)="retypePasswordHasfocus=false" />

              <p *ngIf="!retypeNewPassword.value && !retypePasswordHasfocus && ((submittedOnce && !retypeNewPassword.valid)
                        || (!submittedOnce && retypeNewPassword.touched && !retypeNewPassword.valid))" class="text-left text-danger">
                Password is required</p>

              <p *ngIf="retypeNewPassword.value && !retypePasswordHasfocus && ((submittedOnce && !retypeNewPassword.valid)
                        || (!submittedOnce && retypeNewPassword.touched && !retypeNewPassword.valid))" class="text-left text-danger">
                Enter a valid password </p>

              <p *ngIf="retypeNewPassword.value && !retypePasswordHasfocus && ((submittedOnce && retypeNewPassword.valid && !doesPasswordsMatch(newPassword, retypeNewPassword))
                        || (!submittedOnce && retypeNewPassword.touched && retypeNewPassword.valid && !doesPasswordsMatch(newPassword, retypeNewPassword)))" class="text-left text-danger">
                Password does not match with new password</p>
            </div>
          </div>

          <div class="form-button">
            <div class="form-group">
              <div class="col-sm-10 ">
                <button type="submit" class="btn btn-primary submit-button" (click)="changePassword($event)">Submit</button>
                <button type="button" class="btn btn-primary" (click)="clear()">Clear</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>