<div style="padding:16px 0px;float:left;width:100%">
  <p-accordion *ngFor="let cat of questionList">
    <p-accordionTab header="{{cat.category}}">
      <p-accordion *ngFor="let  faq of cat.items">
        <p-accordionTab header="Question :{{faq.question}}">
          <div>
            <span>Answer:</span>
            <span class="answer">{{faq.answer}}</span>
            <div class="btn-bottom">
              <button type="text" pButton icon="fa fa-pencil" (click)="edit(faq)"></button>
              <button type="text" (click)="confirm(faq._id)" pButton icon="fa fa-trash"></button>
            </div>
          </div>
        </p-accordionTab>
      </p-accordion>
    </p-accordionTab>
  </p-accordion>
  <!-- pagination -->
  <div class="col-sm-10 text-center pagination-container">
    <div class="has-text-centered">
      <div class="spinner" [ngClass]="{ 'hidden': true}"></div>
      <pagination-controls autoHide="true" (pageChange)="getQAList($event)"></pagination-controls>
    </div>
  </div>

  <p-growl [value]="msgs"></p-growl>
  <p-confirmDialog appendTo="body" header="Confirmation" icon="fa fa-question-circle" width="425"></p-confirmDialog>
</div>