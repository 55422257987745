<bs-modal #modal>
    <bs-modal-header [showDismiss]="false">
        <i class="fa fa-close" style="font-size:24px;float: right;cursor: pointer;" (click)="closeModal()"></i>
        <h4 class="modal-title">Apply Flat Trip code</h4>
    </bs-modal-header>
    <bs-modal-body>
        <div class="">
            <!-- search bar -->
            <div class="search-container">
                <div id="imaginary_container">
                    <form [formGroup]="searchForm">
                        <div class="input-group stylish-input-group">
                            <input type="text" formControlName="search" class="form-control" placeholder="Search">
                            <span class="input-group-addon">
                                <button type="submit">
                                    <span class="glyphicon glyphicon-search"></span>
                                </button>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 text-right" style="margin-bottom: 10px;margin-top: 10px">
                <button *ngIf="!isRemove" class="btn btn-success btn-right" (click)="applyFlatRide()">Apply</button>
                <button *ngIf="isRemove" class="btn btn-success btn-right" (click)="removeFlatRide()">Remove</button>
            </div>
            <div class="table table-responsive col-sm-12" *ngIf="verifiedDriverResponse.length > 0">
                <table class="table table-hover table-striped table-bordered">
                    <thead class="table-head">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Phone Number</th>
                            <th>Flat ride</th>
                        </tr>
                    </thead>
                    <tbody id="myTable" *ngIf="isApiResponded && verifiedDriverResponse.length > 0">
                        <tr *ngFor="let data of verifiedDriverResponse | paginate: { id: 'server' ,itemsPerPage: paginationFlatTrip.limit, currentPage: paginationFlatTrip.currentPage , totalItems: paginationFlatTrip.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.firstName | captalize}} {{data.lastName}}</td>
                            <td>{{data.phone}}</td>
                            <td>
                                <input type="checkbox" (change)="checkValue(data._id)" [checked]="data.driverProfile.flatTripCode && data.driverProfile.flatTripCode.code == code">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- pagination -->
            <div class="col-sm-10 text-center" *ngIf="isApiResponded && verifiedDriverResponse.length > 0">
                <div class="has-text-centered">
                    <pagination-controls (pageChange)="getFilteredDrivers($event)" id="server"></pagination-controls>
                </div>
            </div>
            <div *ngIf="isApiResponded && verifiedDriverResponse.length <= 0 && isRemove" style="margin: 15px;">
                No drivers has applied this code.
            </div>
            <div *ngIf="isApiResponded && verifiedDriverResponse.length <= 0 && !isRemove" style="margin: 15px;">
                No drivers found.
            </div>
        </div>
    </bs-modal-body>
    <bs-modal-footer>
        <input type="button" class="btn btn-default" (click)="closeModal()" value="Close" />
    </bs-modal-footer>
</bs-modal>