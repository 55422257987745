<div class="container-fluid">
  <div class="row">

    <div class="col-sm-5 left-col">

      <!-- profile -->
      <div class="col-sm-5">
        <img src="{{getImagePath(driverDetails.profileImageFileId)}}" class="img-responsive img-circle profile-pic" alt="Driver">
      </div>
      <div class="col-sm-7">
        <ul>
          <li>
            <h5>{{driverDetails.firstName | captalize}} {{driverDetails.lastName}}</h5>
          </li>
          <li>
            <h6>{{driverDetails.phone }}</h6>
          </li>
          <li>
            <h6>{{driverDetails.email}}</h6>
          </li>
        </ul>
      </div>

      <!-- Document list -->
      <div class="table-responsive col-sm-12 table">
        <table class="table table-hover table-striped table-bordered">
          <thead class="table-head">
            <tr>
              <th>Document</th>
              <th>Verified</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="myTable">
            <tr *ngIf="driverDoc.insuranceDoc">
              <td>Insurance</td>
              <td>
                <span *ngIf="!driverDoc.insuranceDoc.approved" class="glyphicon glyphicon-remove" style="color:red"></span>
                <span *ngIf="driverDoc.insuranceDoc.approved" class="glyphicon glyphicon-ok" style="color:green"></span>
              </td>
              <td>
                <i class="fa fa-eye fa-2x" aria-hidden="true" [routerLink]="['insurance-doc']"></i>
                <i class="fa fa-check fa-2x" aria-hidden="true" (click)="insuranceApproveReject(true)"></i>
                <i class="fa fa-times fa-2x" aria-hidden="true" (click)="showCommentDialog('insurance')"></i>
              </td>
            </tr>
            <tr *ngIf="driverDoc.vehicleDoc">
              <td>Vehicle</td>
              <td>
                <span *ngIf="!driverDoc.vehicleDoc.approved" class="glyphicon glyphicon-remove" style="color:red"></span>
                <span *ngIf="driverDoc.vehicleDoc.approved" class="glyphicon glyphicon-ok" style="color:green"></span>
              </td>
              <td>
                <i class="fa fa-eye fa-2x" aria-hidden="true" [routerLink]="['vehicle-doc']"></i>
                <i class="fa fa-check fa-2x" aria-hidden="true" (click)="vehicleApproveReject(true)"></i>
                <i class="fa fa-times fa-2x" aria-hidden="true" (click)="showCommentDialog('vehicle')"></i>
              </td>
            </tr>
            <tr *ngIf="!apiCallInProgress">
              <td>Strip Account</td>
              <td>
                <span *ngIf="!driverDetails.stripeProfile || !driverDetails.stripeProfile.stripeAccountId" style="color:red">Not Connnected</span>
                <span *ngIf="driverDetails.stripeProfile && driverDetails.stripeProfile.stripeAccountId" style="color:green">Connected</span>
              </td>
              <td>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <!-- comment box -->
    <p-dialog header="Please provide rejection reason." [(visible)]="display" modal="modal" width="400" (onHide)="dialogHide()"
      [responsive]="true">

      <label for="comment">Comment:</label>
      <textarea class="form-control" rows="5" id="comment" [(ngModel)]="rejectionReason"></textarea>
      <p class="text-danger" *ngIf="rejectionValidation && !rejectionReason">Please give comment on it.</p>

      <p-footer>
        <button type="button" pButton (click)="rejectionComment()" label="Submit"></button>
      </p-footer>
    </p-dialog>


    <div class="col-sm-7">
      <!-- approval button -->
      <div class="toggle-button">
        <p-confirmDialog class='dialog-z-index' appendTo="body" header="Confirmation" icon="fa fa-question-circle" acceptIcon=""
          rejectIcon="" width="425"></p-confirmDialog>
        <button class="approval-button" type="text" (click)="confirm()" pButton icon="fa-check" label="Approve"></button>
      </div>

      <div class="col-sm-12 document">
        <router-outlet></router-outlet>
      </div>

    </div>

  </div>
</div>