<div class="container-fluid">
    <h3>Promo Codes</h3>
    <div class="col-sm-12 text-right" style="margin-bottom: 10px">
        <button class="btn btn-success btn-right" (click)="openPromoDialogue()">Create</button>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Coupon Code</th>
                            <th>Value</th>
                            <th>Type</th>
                            <th>Effective from</th>
                            <th>Effective upto</th>
                            <th>Use count</th>
                        </tr>
                    </thead>
                    <tbody id="myTable" *ngIf="isApiResponded && promoCodeList.length > 0">
                        <tr *ngFor="let data of promoCodeList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.couponCode}}</td>
                            <td>{{data.value}}</td>
                            <td>{{data.type}} </td>
                            <td>{{data.effectiveFrom | date}}</td>
                            <td>{{data.effectiveUpto | date}}</td>
                            <td>{{data.usedCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-10 text-center" *ngIf="isApiResponded && promoCodeList.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getFilterPromocodes($event)" id="server"></pagination-controls>
            </div>
        </div>

    </div>
</div>
<promo-code-form #promoCodeForm (callbackAddPromoCode)="promoCodeAdded($event)"></promo-code-form>