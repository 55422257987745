<div class="container-fluid">
    <h3>Flat Trips</h3>
    <div class="col-sm-12 text-right" style="margin-bottom: 10px">
        <button class="btn btn-success btn-right" (click)="openDialogue()">Create</button>
    </div>
    <div class="row" *ngIf="isApiResponded && flatTripList.length > 0">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Code</th>
                            <th>Value</th>
                            <th>Type</th>
                            <th>Effective from</th>
                            <th>Effective upto</th>
                            <th>Maximum count</th>
                            <th>Description</th>
                            <th>Apply code to Drivers</th>
                        </tr>
                    </thead>
                    <tbody id="myTable">
                        <tr *ngFor="let data of flatTripList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.code}}</td>
                            <td>{{data.value}}</td>
                            <td>{{data.type}} </td>
                            <td>{{data.effectiveFrom | date}}</td>
                            <td>{{data.effectiveUpto | date}}</td>
                            <td>{{data.count}}</td>
                            <td>{{data.description}}</td>
                            <td>
                                <button class="btn btn-success" (click)="openDialogueFlatTrip(data,1)">Apply</button>
                                <button class="btn btn-danger" (click)="openDialogueFlatTrip(data,0)">Remove</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-10 text-center" *ngIf="isApiResponded && flatTripList.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getFilteredCodeList($event)" id="server"></pagination-controls>
            </div>
        </div>

    </div>
</div>

<flat-trips-form #flatTripForm (callbackAddFlatTrips)="flatTripCodeAdded($event)"></flat-trips-form>
<apply-to-drivers #applyToDrivers (callbackAppliedFlatTripCode)="flatTripCodeApplied($event)"></apply-to-drivers>