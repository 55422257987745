<h3>Scheduled Rides</h3>
<div class="col-sm-12 rm-left-padding">
    <form [formGroup]="filterForm" novalidate (submit)="applyFilters(filterForm)">
        <div class="col-sm-12 filter-container">
            <div class="col-sm-12">
                <div class="col-sm-4 form-group">
                    <label for="dateFilter" class="form-control-label">Select Status </label>
                    <angular2-multiselect [data]="itemList" [(ngModel)]="selectedItems" [settings]="settings" formControlName="status">
                    </angular2-multiselect>
                </div>
                <div class="col-sm-4 form-group">
                    <label for="dateFilter" class="form-control-label">Select from-to date </label>
                    <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                </div>
                <div class="col-sm-4 " style="margin-top: 2%;">
                    <button type="submit" class="btn btn-primary">Apply</button>
                    <button type="button" class="btn btn-primary" (click)="clearFilters($event)">Clear</button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="row">
    <div>
        <!-- Table -->
        <div class="table-responsive col-sm-12">
            <table class="table table-hover table-striped table-bordered">
                <thead>
                    <!-- 
             
               
                "rider" : ObjectId("59818428ef0f14189fad5354"),
                "riderSelectedFare" : 200, -->
                    <tr>
                        <th>#</th>
                        <th>Rider</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Pickup Date</th>
                        <th>Distance</th>
                        <th>Duration</th>
                        <th>Car Type</th>
                        <th>Rider Selected Fare</th>
                        <th>Status</th>
                        <th>Closed on</th>
                    </tr>
                </thead>
                <tbody id="myTable" *ngIf="isApiResponded && scheduleList.length > 0">
                    <tr *ngFor="let data of scheduleList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                        <td>{{index+i+1}}</td>
                        <td>{{data.rider.firstName | captalize}} {{data.rider.lastName}}</td>
                        <td>{{data.fromAddress}}</td>
                        <td>{{data.toAddress}} </td>
                        <td>{{data.pickupDateTime | date}}</td>
                        <td>{{data.estDistance/(1000*1.6) | roundOff}} Miles</td>
                        <td> {{data.estDuration/(1000*60) | roundOff}} Mins</td>
                        <td>{{data.carType}}</td>
                        <td>{{data.riderSelectedFare}}</td>
                        <td>{{data.status}}</td>
                        <td>{{data.closeBy | date}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <!-- pagination -->
        <div class="col-md-10 text-center" *ngIf="isApiResponded && scheduleList.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getFilteredAuctions($event)" id="server"></pagination-controls>
            </div>
        </div>
    </div>

    <!-- for no data-->
    <div *ngIf="isApiResponded && scheduleList.length <= 0" class="col-sm-10 text-center">
        <h2>No scheduled rides found</h2>
    </div>
</div>