<label class="name">Vehicle Info:</label>

<h4>Year of vehicle : {{vehicleDoc.year?vehicleDoc.year:''}}</h4>
<h4>Make of vehicle : {{vehicleDoc.make?vehicleDoc.make:''}}</h4>
<h4>Model of vehicle : {{vehicleDoc.model?vehicleDoc.model:''}}</h4>
<h4>Company/Corporate name : {{vehicleDoc.companyName?vehicleDoc.companyName:''}}</h4>
<h4>License plate number : {{vehicleDoc.carPlateNumber?vehicleDoc.carPlateNumber:''}}</h4>
<h4>Car type : {{vehicleDoc.carType?vehicleDoc.carType.vehicleType:''}}</h4>

<div>
  <ngx-gallery [options]="galleryOptions" [images]="galleryImages"></ngx-gallery>
</div>
