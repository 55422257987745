<div class="row slider closed" [ngClass]="{'closed': !showFilters}">
  <!--Location filter is not needed now-->
  <!--<div class="filter-container">
      <label>Location: </label>
      <div class="input-group filter-comp">
        <div class="search-container">
          <div id="imaginary_container">
            <div class="input-group stylish-input-group">
              <input type="text" class="form-control" placeholder="Location">
              <span class="input-group-addon">
                        <button type="submit" class="location-search-button">
                            <span class="glyphicon glyphicon-search"></span>
              </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  <form [formGroup]="filterForm">
    <div [ngClass]="{'filter-container': true, 'hide': hideRegistrationFilter}">
      <label>Registration Date: </label>
      <div class="input-group filter-comp">
        <input formControlName="startDate" class="form-control" style="width:150px; display: inline-block; outline: none" placeholder="Date"
          ngx-mydatepicker [(ngModel)]="startDateModel" [options]="startDatePickerOptions" #dp="ngx-mydatepicker" required=false/>
        <span class="input-group-btn" style="display: inline-block; vertical-align: bottom">
            <button type="button" class="btn btn-default" style="padding:6px 10px"(click)="dp.toggleCalendar()">
                <i class="glyphicon glyphicon-calendar"></i>
            </button>
          </span>
      </div>
    </div>

    <div [ngClass]="{'filter-container': true, 'hide': hideRatingFilter}">
      <label>Rating: </label>
      <div class="input-group filter-comp">
        <div class="rating-row">
          <input type="radio" class="regular-checkbox" name="rating-one" [checked]="toggles[3].toggle" (change)="onCheckChange($event, 3)">
          <star-rating-comp [starType]="'svg'" [staticColor]="'default'" [space]="'around'" getHalfStarVisible=false [rating]="4" readOnly=true></star-rating-comp>
          <div class="up">& up</div>
        </div>
        <div class="rating-row">
          <input type="radio" class="regular-checkbox" name="rating-one" [checked]="toggles[2].toggle" (change)="onCheckChange($event, 2)">
          <star-rating-comp [starType]="'svg'" [staticColor]="'default'" [space]="'around'" getHalfStarVisible=false [rating]="3" readOnly=true></star-rating-comp>
          <div class="up">& up</div>
        </div>
        <div class="rating-row">
          <input type="radio" class="regular-checkbox" name="rating-one" [checked]="toggles[1].toggle" (change)="onCheckChange($event, 1)">
          <star-rating-comp [starType]="'svg'" [staticColor]="'default'" [space]="'around'" getHalfStarVisible=false [rating]="2" readOnly=true></star-rating-comp>
          <div class="up">& up</div>
        </div>
        <div class="rating-row">
          <input type="radio" class="regular-checkbox" name="rating-one" [checked]="toggles[0].toggle" (change)="onCheckChange($event, 0)">
          <star-rating-comp [starType]="'svg'" [staticColor]="'default'" [space]="'around'" getHalfStarVisible=false [rating]="1" readOnly=true></star-rating-comp>
          <div class="up">& up</div>
        </div>
      </div>
    </div>

    <div [formGroup]="tripTakenForm" [ngClass]="{'filter-container': true, 'hide': hideTripTakenFilter}">
      <label>Trips Taken: </label>
      <div class="trip-taken-comp">
        <div class="min-comp-container">
          <div>
            <input formControlName="minTrip" type="text" [ngClass]="{'min-comp': true, 'form-control': true, 'error': !minTripTakenCtl.valid || ( isCtrlEmpty(minTripTakenCtl) && !isCtrlEmpty(maxTripTakenCtl)) }"
              placeholder="MIN" required=false (change)="onTripTakenMinValueChanged($event)">
          </div>
          <div>
          </div>
        </div>
        <div class="max-comp-container">
          <div>
            <input formControlName="maxTrip" type="text" [ngClass]="{'max-comp': true, 'form-control': true, error: (!maxTripTakenCtl.valid || ( isCtrlEmpty(maxTripTakenCtl) && !isCtrlEmpty(minTripTakenCtl))) }"
              placeholder="MAX" required=false (change)="onTripTakenMaxValueChanged($event)">
          </div>
          <div>
          </div>
        </div>
      </div>
    </div>

    <div [ngClass]="{'filter-container': true, 'hide': hideUserStatusFilter}">
      <label>User Status: </label>
      <div class="input-group filter-comp">
        <div class="form-group">
          <angular2-multiselect [data]="statusItemList" [(ngModel)]="selectedStatuses" [settings]="statusfilterSettings" formControlName="status">
          </angular2-multiselect>
        </div>
      </div>
    </div>

    <div class="filter-action-buttons">
      <button type="button" class="btn btn-primary column submit submit-button" (click)="applyFilters($event)">Apply</button>
      <button type="submit" class="btn btn-primary column clear" (click)="clearFilters($event)">Clear</button>
    </div>
  </form>
</div>