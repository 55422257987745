<!-- Create Promo code -->
<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Create Promo Code</h4>
    </bs-modal-header>
    <form [formGroup]="promocodeForm" (submit)="onSubmit(promocodeForm)">
        <bs-modal-body>
            <div class="form-group row" style="display:flex;">
                <div class="col-sm-8">
                    <label class="form-control-label"> Promo Code</label>
                    <input type="text" name="promoCode" class="form-control" placeholder="Promo Code" formControlName="couponCode">
                </div>
                <div class="col-sm-4">
                    <input type="button" value="Get Code" class="btn btn-success" style="bottom: 0;
                position: absolute;width: 80%" (click)="getCode()">
                </div>
            </div>
            <p *ngIf="(!promocodeForm.controls.couponCode.valid && promocodeForm.controls.couponCode.touched) || (!promocodeForm.controls.couponCode.valid && submittedOnce)"
                class="text-left text-danger">
                Coupon Code is required</p>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label class="form-control-label">Value</label>
                    <input type="number" name="" class="form-control" placeholder="Value" formControlName="value">
                    <p *ngIf="(!promocodeForm.controls.value.valid && promocodeForm.controls.value.touched) || (!promocodeForm.controls.value.valid && submittedOnce)"
                        class="text-left text-danger">
                        Value is required</p>
                </div>
                <div class="col-sm-6">
                    <label class="form-control-label">Type </label>
                    <div class="radio">
                        <label class="radio-inline">
                            <input type="radio" name="type" formControlName="type" value="$"> $</label>
                        <label class="radio-inline">
                            <input type="radio" name="type" formControlName="type" value="%"> %</label>
                    </div>
                    <p *ngIf="(!promocodeForm.controls.type.valid && promocodeForm.controls.type.touched) || (!promocodeForm.controls.type.valid && submittedOnce)"
                        class="text-left text-danger">
                        Type is required</p>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label class="form-control-label">Can be use</label>
                    <input type="number" name="usedCount" class="form-control" formControlName="usedCount" placeholder="Ex- 50 times">
                    <p *ngIf="(!promocodeForm.controls.usedCount.valid && promocodeForm.controls.usedCount.touched) || (!promocodeForm.controls.usedCount.valid && submittedOnce)"
                        class="text-left text-danger">
                        Use count is required</p>
                </div>
                <div class="col-sm-6">
                    <label for="effectiveUpto" class="form-control-label">Select Effective From-Upto </label>
                    <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                    <p *ngIf="(!promocodeForm.controls.dateRange.valid && promocodeForm.controls.dateRange.touched) || (!promocodeForm.controls.dateRange.valid && submittedOnce)"
                        class="text-left text-danger">
                        Date range is required</p>
                </div>
            </div>
        </bs-modal-body>
        <bs-modal-footer>
            <input type="button" class="btn btn-default" (click)="closeModal()" value="Cancel" />
            <input type="submit" class="btn btn-primary" value="Create" />
        </bs-modal-footer>
    </form>
</bs-modal>