<!-- Create Promo code -->
<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Create Event</h4>
    </bs-modal-header>
    <form [formGroup]="eventForm" (submit)="onSubmit(eventForm)">
        <bs-modal-body>
            <!-- form goes here. -->
            <div class="form-group row">
                <div class="col-sm-6">
                    <div class="col-sm-12">
                        <label class="form-control-label"> Category</label>
                        <select class="form-control" formControlName="category" name="category">
                            <option *ngFor="let c of categories" value="{{c._id}}">{{c.shortName}}</option>
                        </select>
                        <p *ngIf="(!eventForm.controls.category.valid && eventForm.controls.category.touched) || (!eventForm.controls.category.valid && submittedOnce)"
                            class="text-left text-danger">
                            Category is required</p>
                    </div>
                </div>
            </div>
            <div class="form-group row" style="display:flex;">
                <div class="col-sm-6">
                    <div class="col-sm-12">
                        <label class="form-control-label"> Event Name</label>
                        <input type="text" name="name" class="form-control" placeholder="Event Name" formControlName="name">
                        <p *ngIf="(!eventForm.controls.name.valid && eventForm.controls.name.touched) || (!eventForm.controls.name.valid && submittedOnce)"
                            class="text-left text-danger">
                            Event Name is required</p>
                    </div>
                    <div class="col-sm-12">
                        <label for="date" class="form-control-label">Event date </label>
                        <input type="date" class="form-control" formControlName="eventDate">
                        <p *ngIf="(!eventForm.controls.eventDate.valid && eventForm.controls.eventDate.touched) || (!eventForm.controls.eventDate.valid && submittedOnce)"
                            class="text-left text-danger">
                            Date is required</p>
                    </div>
                </div>

                <div class="col-sm-6">
                    <label class="form-control-label"> Description</label>
                    <textarea class="form-control" name="description" formControlName="description" cols="5" rows="5"></textarea>
                    <p *ngIf="(!eventForm.controls.description.valid && eventForm.controls.description.touched) || (!eventForm.controls.description.valid && submittedOnce)"
                        class="text-left text-danger">
                        Description is required</p>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-6">
                    <label class="form-control-label"> Image</label>
                    <image-upload [buttonCaption]="'Select image'" [max]="1" [extensions]="['jpg','png','gif']" [dropBoxMessage]="'Drop your image here!'"
                        (removed)="onRemoveProfileImage($event)" (uploadFinished)="onProfileImageUploadFinished($event)"></image-upload>
                    <p *ngIf="(!eventForm.controls.coverImage.valid && eventForm.controls.coverImage.touched) || (!eventForm.controls.coverImage.valid && submittedOnce)"
                        class="text-left text-danger" style="margin-left:15px;">
                        Image is required</p>
                </div>
                <div class="col-sm-6">
                    <label class="form-control-label"> Promo codes</label>
                    <ng-select [allowClear]="true" [items]="promoCodeList" (selected)="onCodeSelected($event)" (removed)="onCodeRemoved($event)"
                        placeholder="No Code selected">
                    </ng-select>
                    <p *ngIf="(!eventForm.controls.promoCode.valid && eventForm.controls.promoCode.touched) || (!eventForm.controls.promoCode.valid && submittedOnce)"
                        class="text-left text-danger">
                        Promocode is required</p>
                </div>
            </div>
            <div class="form-group row">
                <search-address (searchResult)="searchedResultData($event)"></search-address>
                <p *ngIf="(!eventForm.controls.address.valid && eventForm.controls.address.touched) || (!eventForm.controls.address.valid && submittedOnce)"
                    class="text-left text-danger" style="margin-left:15px;">
                    Address is required</p>
            </div>

        </bs-modal-body>
        <bs-modal-footer>
            <input type="button" class="btn btn-default" value="Cancel" (click)="closeModal()">
            <input type="submit" class="btn btn-primary" value="Create">
        </bs-modal-footer>
    </form>
</bs-modal>