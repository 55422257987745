<div class="col-sm-12" style="margin-bottom: 10px">
    <h2>
        Ehail Trip Requests
    </h2>
    <form [formGroup]="requestForm" (submit)="onSubmit(requestForm)">
        <div class="col-sm-12">
            <label for="effectiveUpto" class="form-control-label">Select date </label>
        </div>
        <div class="">
            <div class="col-sm-3" style="display: table">
                <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                <p *ngIf="(!requestForm.controls.dateRange.valid && requestForm.controls.dateRange.touched) || (!requestForm.controls.dateRange.valid && submittedOnce)"
                    class="text-left text-danger">
                    Date range is required</p>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-primary">Export Requests Flex fare</button>
            </div>
        </div>
    </form>
</div>