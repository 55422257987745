<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Apply Flat Trip code</h4>
    </bs-modal-header>
    <bs-modal-body>
        <div class="row" *ngIf="isApiResponded && flatTripList.length > 0">
            <div class="col-sm-12">
                <div class="table-responsive col-sm-12">
                    <table class="table table-hover table-striped table-bordered">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Value</th>
                                <th>Type</th>
                                <th>Apply</th>
                            </tr>
                        </thead>
                        <tbody id="myTable">
                            <tr *ngFor="let data of flatTripList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                                <td>{{index+i+1}}</td>
                                <td>{{data.code}}</td>
                                <td>{{data.value}}</td>
                                <td>{{data.type}}</td>
                                <td>
                                    <button class="btn btn-success" (click)="applyCode(data.code)"> Apply</button>
                                    <button class="btn btn-success" (click)="removeCode(data.code)"> Remove</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-10 text-center" *ngIf="isApiResponded && flatTripList.length > 0">
                <div class="has-text-centered">
                    <pagination-controls (pageChange)="getFilteredCodeList($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
    </bs-modal-body>
    <bs-modal-footer>
        <input type="button" class="btn btn-default" (click)="closeModal()" value="Close" />
    </bs-modal-footer>
</bs-modal>