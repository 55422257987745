<bs-modal #modal>
    <bs-modal-header [showDismiss]="true">
        <h4 class="modal-title">Create Category</h4>
    </bs-modal-header>
    <form [formGroup]="eventCategoryForm" (submit)="onSubmit(eventCategoryForm)">
        <bs-modal-body>

            <div class="form-group row" style="display:flex;">
                <!-- name, shortname -->
                <div class="col-sm-6">
                    <div class="col-sm-12">
                        <label class="form-control-label"> Category Name</label>
                        <input type="text" name="name" class="form-control" placeholder="Category Name" formControlName="name">
                        <p *ngIf="(!eventCategoryForm.controls.name.valid && eventCategoryForm.controls.name.touched) || (!eventCategoryForm.controls.name.valid && submittedOnce)"
                            class="text-left text-danger">
                            Category Name is required</p>
                    </div>
                    <div class="col-sm-12">
                        <label for="Short name" class="form-control-label">Short name </label>
                        <input type="text" class="form-control" formControlName="shortName" placeholder="Category short Name">
                        <p *ngIf="(!eventCategoryForm.controls.shortName.valid && eventCategoryForm.controls.shortName.touched) || (!eventCategoryForm.controls.shortName.valid && submittedOnce)"
                            class="text-left text-danger">
                            ShortName is required</p>
                    </div>
                </div>
                <!-- description -->
                <div class="col-sm-6">
                    <label class="form-control-label"> Description</label>
                    <textarea class="form-control" name="description" formControlName="description" cols="5" rows="5" placeholder="description"></textarea>
                    <p *ngIf="(!eventCategoryForm.controls.description.valid && eventCategoryForm.controls.description.touched) || (!eventCategoryForm.controls.description.valid && submittedOnce)"
                        class="text-left text-danger">
                        Description is required</p>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-6">
                    <div class="col-sm-12">
                        <label for="effectiveUpto" class="form-control-label">Select Effective From-Upto </label>
                        <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                        <p *ngIf="(!eventCategoryForm.controls.dateRange.valid && eventCategoryForm.controls.dateRange.touched) || (!eventCategoryForm.controls.dateRange.valid && submittedOnce)"
                            class="text-left text-danger">
                            Date range is required</p>
                    </div>
                </div>
                <div class="col-sm-6">
                        <label class="form-control-label"> Image</label>
                        <image-upload [buttonCaption]="'Select image'" [max]="1" [extensions]="['jpg','png','gif']" [dropBoxMessage]="'Drop your image here!'"
                            (removed)="onRemoveProfileImage($event)" (uploadFinished)="onProfileImageUploadFinished($event)"></image-upload>
                        <p *ngIf="(!eventCategoryForm.controls.coverImage.valid && eventCategoryForm.controls.coverImage.touched) || (!eventCategoryForm.controls.coverImage.valid && submittedOnce)"
                            class="text-left text-danger" style="margin-left:15px;">
                            Image is required</p>
                    </div>
            </div>
            <div class="form-group row">
                <search-address (searchResult)="searchedResultData($event)">
                </search-address>
                <p *ngIf="(!eventCategoryForm.controls.address.valid && eventCategoryForm.controls.address.touched) || (!eventCategoryForm.controls.address.valid && submittedOnce)"
                    class="text-left text-danger" style="margin-left:15px;">
                    Address is required</p>
            </div>
        </bs-modal-body>
        <bs-modal-footer>
            <input type="button" class="btn btn-default" value="Cancel" (click)="closeModal()">
            <input type="submit" class="btn btn-primary" value="Create">
        </bs-modal-footer>
    </form>
</bs-modal>