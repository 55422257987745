<div class="container-fluid">
  <div class="row row.match-my-cols">
    <div class="col-sm-2 left_col">
      <side-menu [unverifiedDocumentCount]="unverifiedDocumentCount"></side-menu>
    </div>
    <div class="col-sm-10 right_col">
      <!-- header start -->
      <div class="text-right header">
        <div class="btn-group" dropdown>
          <a dropdownToggle class="btn  dropdownToggle">
            Admin
            <span class="caret"></span>
          </a>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
            <li role="menuitem">
              <a class="dropdown-item" href="#">Profile</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="logout()">Logout</a>
            </li>
          </ul>
        </div>
      </div>
      <!-- header end -->
      <!-- Router container -->
      <div #homeContent class="home-content">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>