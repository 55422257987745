<div class="container-fluid" style="margin-bottom: 10px">
    <div class="col-sm-12" style="margin-bottom: 10px">
        <h2>
            Ehail Tripsheet
        </h2>
        <form [formGroup]="ehailTripSheetForm" (submit)="onSubmit(ehailTripSheetForm)">
            <div class="col-sm-12">
                <label for="effectiveUpto" class="form-control-label">Select date </label>
            </div>
            <div class="">
                <div class="col-sm-3" style="display: table">
                    <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                    <p *ngIf="(!ehailTripSheetForm.controls.dateRange.valid && ehailTripSheetForm.controls.dateRange.touched) || (!ehailTripSheetForm.controls.dateRange.valid && submittedOnce)"
                    class="text-left text-danger">
                    Date range is required</p>
                </div>
                <div class="col-sm-3">
                    <button class="btn btn-primary" style="">Export Tripsheet</button>
                </div>
            </div>
        </form>

    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>App</th>
                            <th>Trip_RequestNum</th>
                            <th>Vehicle</th>
                            <th>Hack_number</th>
                            <th>Trip_Number</th>
                            <th>TPEP_Pickup_datetime</th>
                            <th>Fare_amount</th>
                            <th>Extra</th>
                            <th>Tolls_amount</th>
                            <th>MTA_tax</th>
                            <th>Improvement_surcharge</th>
                            <th>Ehail_fee</th>
                            <th>Tip_amount</th>
                            <th>CC_brand</th>
                            <th>CC_number</th>
                        </tr>
                    </thead>
                    <tbody id="myTable" *ngIf="isApiResponded && ehailTripsheetDataList.length > 0">
                        <tr *ngFor="let data of ehailTripsheetDataList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.app}}</td>
                            <td>{{data.Trip_RequestNum}}</td>
                            <td>{{data.Vehicle}}</td>
                            <td>{{data.Hack_number}}</td>
                            <td>{{data.Trip_Number}}</td>
                            <td>{{data.TPEP_Pickup_datetime | date:'MM-dd-yyyy hh:mm:ss' }}</td>
                            <td>{{data.Fare_amount}}</td>
                            <td>{{data.Extra }}</td>
                            <td>{{data.Tolls_amount}} </td>
                            <td>{{data.MTA_tax}}</td>
                            <td>{{data.Improvement_surcharge}}</td>
                            <td>{{data.Ehail_fee}}</td>
                            <td>{{data.Tip_amount}}</td>
                            <td>{{data.CC_brand}}</td>
                            <td>{{data.CC_number}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-10 text-center" *ngIf="isApiResponded && ehailTripsheetDataList.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getEhailTripsheetDataList($event)" id="server"></pagination-controls>
            </div>
        </div>
    </div>

</div>