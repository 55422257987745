<div class="col-sm-12 tab-body">
  <p-tabView (onChange)="onRideTabClick($event)">
    <p-tabPanel header="Personal Info" (click)="personalInfo()">
      <div class="container-fluid">
        <div class="row" *ngIf="driverDetails">
          <!-- personal Info -->
          <div class="col-sm-5 left-col">

            <div class='profil-pic-container'>
              <img src="{{getImagePath(driverDetails.profileImageFileId)}}" class="img-responsive img-rounded profile-pic" alt="Cinque Terre">
            </div>

            <!-- driver detail -->
            <div class="personal-detail">

              <div class="driver-details">

                <ul>
                  <li class="name">
                    {{driverDetails.firstName | captalize}} {{driverDetails.lastName}}
                  </li>
                  <li class="detail">
                    <i class="fa fa-phone" aria-hidden="true"></i> {{driverDetails.phone}}
                  </li>
                  <li class="detail">
                    <i class="fa fa-envelope" aria-hidden="true"></i> {{driverDetails.email?driverDetails.email:'NA'}} {{driverDetails.emailVerified?'':'(Unverified)'}}
                  </li>
                </ul>

              </div>
              <!-- other details -->
              <div class="driver-details">
                <ul>
                  <li class="name">
                    Other Details
                  </li>
                  <li class="detail">
                    Total Earnings:
                    <span *ngIf="driverDetails">{{driverDetails.totalEarning ?driverDetails.totalEarning :'NA'}} </span>
                  </li>
                  <li class="detail">
                    Date Of Registration:
                    <span *ngIf="driverDetails">{{driverDetails.createdAt}} </span>
                  </li>
                  <li class="detail">
                    Date Of Approval:
                    <span *ngIf="driverDetails">{{approvedAt}}</span>
                  </li>
                  <li class="detail">
                    OnGoing rate:
                    <span *ngIf="driverDetails">{{driverDetails.driverProfile.ongoingRate}}</span>
                  </li>
                  <li class="detail">
                    Ratio:
                    <span *ngIf="driverDetails">{{driverDetails.driverProfile.ratio}}</span>
                  </li>
                  <li class="detail">
                    Rides Taken successfully:
                    <span *ngIf="driverDetails">{{driverDetails.driverProfile.statsTripSuccessCount}}</span>
                  </li>
                </ul>
              </div>

            </div>

          </div>

          <div class="col-sm-7 left-col">

            <!-- Document list -->
            <div class="table-responsive col-sm-12 table">
              <table class="table table-hover table-striped table-bordered">
                <thead class="table-head">
                  <tr>
                    <th>Document</th>
                    <th>Verified</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody id="myTable">
                  <tr *ngIf="driverDoc.insuranceDoc">
                    <td>Insurance</td>
                    <td>
                      <span *ngIf="!driverDoc.insuranceDoc.approved" class="glyphicon glyphicon-remove" style="color:red"></span>
                      <span *ngIf="driverDoc.insuranceDoc.approved" class="glyphicon glyphicon-ok" style="color:green"></span>
                    </td>
                    <td>
                      <i class="fa fa-eye fa-2x" aria-hidden="true" [routerLink]="['insurance-doc']"></i>
                    </td>
                  </tr>
                  <tr *ngIf="driverDoc.vehicleDoc">
                    <td>Vehicle</td>
                    <td>
                      <span *ngIf="!driverDoc.vehicleDoc.approved" class="glyphicon glyphicon-remove" style="color:red"></span>
                      <span *ngIf="driverDoc.vehicleDoc.approved" class="glyphicon glyphicon-ok" style="color:green"></span>
                    </td>
                    <td>
                      <i class="fa fa-eye fa-2x" aria-hidden="true" [routerLink]="['vehicle-doc']"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- document Action perform list -->
            <div class="col-sm-12 document">
              <router-outlet></router-outlet>
            </div>
          </div>

        </div>
      </div>

    </p-tabPanel>
    <p-tabPanel header="Ride Details">
      <router-outlet name="child2"></router-outlet>
    </p-tabPanel>
  </p-tabView>
</div>