<!-- Verified drivers -->

<div class="container-fluid">

  <div class="row">

    <div *ngIf="isApiResponded && unverifiedDriverResponse.length > 0">


      <!-- search bar -->
      <div class="col-sm-6 col-sm-offset-6 search-container">
        <div id="imaginary_container">
          <div class="input-group stylish-input-group">
            <input type="text" class="form-control" placeholder="Search">
            <span class="input-group-addon">
                        <button type="submit">
                            <span class="glyphicon glyphicon-search"></span>
            </button>
            </span>
          </div>
        </div>
      </div>

      <!-- Table -->
      <div class="table-responsive col-sm-12">
        <table class="table table-hover table-striped table-bordered">
          <thead class="table-head">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone Number</th>
              <th>Email</th>
              <th>Date of registration</th>
              <th>Stripe</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody id="myTable">
            <tr *ngFor="let data of unverifiedDriverResponse | paginate: { id: 'server' ,itemsPerPage: unverifiedDriverPagination.limit, currentPage: unverifiedDriverPagination.currentPage , totalItems: unverifiedDriverPagination.total_count } ; let i=index ">
              <td>{{index+i+1}}</td>
              <td>{{data.firstName | captalize}} {{data.lastName}}</td>
              <td>{{data.phone}}</td>
              <td>{{data.email?data.email:"NA"}} </td>
              <td>{{data.createdAt | date}}</td>
              <td>
                <span *ngIf="!data.stripeProfile || !data.stripeProfile.stripeAccountId" style="color:red">Not Connnected</span>
                <span *ngIf="data.stripeProfile && data.stripeProfile.stripeAccountId" style="color:green">Connected</span>
              </td>
              <td>
                <i class="fa fa-eye fa-2x" aria-hidden="true" (click)="unVerifiedDriverDetail(data)"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- pagination -->
      <div class="col-sm-10 text-center">
        <div class="has-text-centered">
          <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
          <pagination-controls (pageChange)="getUnverifiedDrivers($event)" id="server"></pagination-controls>
        </div>
      </div>

    </div>

    <!-- for no data-->
    <div *ngIf="isApiResponded && unverifiedDriverResponse.length <= 0" class="col-sm-10 text-center">
      <h2>No new registered driver</h2>
    </div>

  </div>
</div>