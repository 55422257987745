<div class="vertical-menu-header">
    <img src="../../../assets/images/Logo-wapanda.png" alt="" width="120">
    <a routerLink="/">Wapanda</a>
</div>
<div class="vertical-menu">
    <a routerLink="/tlc" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        </div>
        <span> Dashboard</span>

    </a>
</div>