<div class="">
    <h3>
        TLC Managers
    </h3>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <!-- table goes here -->
                <table class="table table-hover table-striped table-bordered">
                    <thead class="table-head">
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody id="myTable" *ngIf="isApiResponded && managers.length > 0">
                        <tr *ngFor="let data of managers | paginate: { id: 'server' ,itemsPerPage: pagination.limit, currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.firstName | captalize}} {{data.lastName}}</td>
                            <td>{{data.email}}</td>
                            <td>
                                <i *ngIf="data.isActive" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="data.isActive=!data.isActive"></i>

                                <i *ngIf="!data.isActive" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="data.isActive=!data.isActive"></i>

                                <i class="fa fa-pencil fa-2x" aria-hidden="true"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- pagination -->
        <div class="col-sm-10 text-center" *ngIf="isApiResponded && managers.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getAllManagers($event)" id="server"></pagination-controls>
            </div>
        </div>

    </div>
</div>