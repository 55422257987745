<div class="coupon col-sm-12" style="display: inline-table;margin:5px; ">
  <div style="float:right">
    <i *ngIf="eventData.isActive" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="changeStatus(eventData._id,false)"></i>
    <i *ngIf="!eventData.isActive" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeStatus(eventData._id,true)"></i>
  </div>
  <div>
    <h3 style="word-break: break-word;">{{eventData.name}}</h3>
  </div>
  <div *ngIf="imgUrl" style="width:100%;height: 150px">
    <img src="{{eventData.imgageUrl}}" style="height: 150px;max-width:100%;"   onError="this.src='../../../../assets/images/JCAST.jpeg';"  alt="..." />
  </div>
  <div>

    <h6 style="word-break: break-word;">
      <b>Description</b>: <span>
        {{eventData.description}}
      </span>
    </h6>
    <p> <b>Address</b>: {{eventData.address}} </p>
  </div>
  <div>
    <p>
      <span> <b>Promo</b>: {{eventData.promoCode}}</span>
    </p>
  </div>
  <div>

    <div style="float: left">
      <b>From</b> Date: {{eventData.fromDate| date}} <br>
      <b>To</b> Date: {{eventData.toDate| date}}
    </div>

  </div>
</div>