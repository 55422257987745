<p-tabView (onChange)="onRideTabClick($event)">
    <p-tabPanel header="Personal Info" (click)="personalInfo()">
        <router-outlet></router-outlet>
        <div class="container-fluid">
            <div class="row" *ngIf="this.riderDetails">
                <div class="col-sm-4 left-col">
                    <img src="{{getImagePath(riderDetails.profileImageFileId)}}" class="img-responsive img-rounded profile-pic" alt="Cinque Terre">
                </div>
                <div class="col-sm-8 right-col">
                    <div class="rider-details">
                        <ul>
                            <li>
                                <h3>Name : {{riderDetails.firstName | captalize}} {{riderDetails.lastName}} </h3>
                            </li>
                            <li>
                                <h4>Phone : {{riderDetails.phone}}</h4>
                            </li>
                            <li *ngIf="riderDetails.email">
                                <h4>Email : {{riderDetails.email}} {{riderDetails.emailVerified?'':'(Not Verified)'}}</h4>
                            </li>
                            <li>
                                <h4>Total Rides : {{riderDetails.riderProfile? riderDetails.riderProfile.statsTripCount:0}}</h4>
                            </li>
                            <li *ngIf="riderDetails.address">
                                <h4>Address : {{riderDetails.address}}</h4>
                            </li>
                            <li *ngIf="riderDetails.riderProfile && riderDetails.riderProfile.rating">
                                <h4>Rating : {{riderDetails.riderProfile.rating}}</h4>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </p-tabPanel>
    <p-tabPanel header="Ride Details">
        <router-outlet name="child2"></router-outlet>
    </p-tabPanel>
</p-tabView>