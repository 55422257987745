<div class="container-fluid">
    <h3>Events</h3>
    <h6>Import Excel file</h6>
    <div class="col-sm-6">
       <import-events #importevent ></import-events> 
    </div>
    <div class="col-sm- 6" style="margin-bottom: 10px;float: right">
        <button class="btn btn-primary btn-right" (click)="openEventCategories()">Event Categories</button>
        <button class="btn btn-success btn-right" (click)="openEventDialogue()">Create</button>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <div *ngIf="isApiResponded && eventList.length > 0">
                    <div *ngFor="let event of eventList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index "
                        class="col-sm-4" style="height: 365px;
                    overflow-y: scroll; text-overflow: ellipsis;">
                        <event-details [eventData]="event"></event-details>
                    </div>
                </div>
            </div>
            <div class="col-md-10 text-center" *ngIf="isApiResponded && eventList.length > 0">
                <div class="has-text-centered">
                    <pagination-controls (pageChange)="getAllEvents($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div>
    </div>

    <event-form #eventForm (callbackAddEvent)="eventAdded($event)"></event-form>