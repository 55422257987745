<div class="row">
  <div class="col-sm-12 rm-left-padding" style="margin-bottom:15px;">
    <form [formGroup]="filterForm" novalidate (submit)="applyFilters(filterForm)">
      <div class="col-sm-12 filter-container">
        <div class="col-sm-8">
          <div class="col-sm-4">
            <label for="dateFilter" class="form-control-label">Select Status </label>
            <angular2-multiselect [data]="itemList" [(ngModel)]="selectedItems" [settings]="settings"
              formControlName="status">
            </angular2-multiselect>
          </div>
          <div class="col-sm-4">
            <label for="dateFilter" class="form-control-label">Select Cancelled By</label>
            <angular2-multiselect [data]="cancelledList" [(ngModel)]="selectedCancelledItems"
              [settings]="cancelledSettings" formControlName="cancelledBy">
            </angular2-multiselect>
          </div>
          <div class="col-sm-4">
            <label for="dateFilter" class="form-control-label">Select from-to date </label>
            <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange"
              (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="col-sm-6" style="text-align: center;">
            <label for="TaxiOSRide" class="form-control-label" style="margin-right: 15px;
            margin-top: 15px;"> TaxiOSRides </label>
            <input type="checkbox" class="" formControlName="isTaxiOSRide" name="isTaxiOSRide">
          </div>
          <div class="col-sm-6 text-right" style="padding-right: 0">
            <button type="submit" class="btn btn-primary">Apply</button>
            <button type="button" class="btn btn-primary" (click)="clearFilters($event)">Clear</button>
          </div>
        </div>
      </div>
      <div>

      </div>
    </form>
  </div>
</div>
<div class="table-responsive">
  <div class="row">
    <div class="col-sm-12">
      <table class="table table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th>#</th>

            <th>
              <div style="display: inline-flex;height:20px">
                Date
              </div>
            </th>
            <th>
              <div class="div-sort-icon">
                Driver
                <span class="sort-icon">
                  <img src="../../../assets/icons/sort.jpeg" alt="" height="16px;"
                    (click)="sortList('driver.firstName')" class="img-sort-icon">
                </span>
              </div>
            </th>
            <th>
              <div class="div-sort-icon">
                Rider
                <span class="sort-icon">
                  <img src="../../../assets/icons/sort.jpeg" alt="" height="16px;" (click)="sortList('rider.firstName')"
                    class="img-sort-icon">
                </span>
              </div>
            </th>
            <th>
              <div class="div-sort-icon">
                Source
                <span class="sort-icon">
                  <img src="../../../assets/icons/sort.jpeg" alt="" height="16px;" (click)="sortList('fromAddress')"
                    class="img-sort-icon">
                </span>
              </div>
            </th>
            <th>

              <div class="div-sort-icon">
                Destination
                <span class="sort-icon">
                  <img src="../../../assets/icons/sort.jpeg" alt="" height="16px;" (click)="sortList('toAddress')"
                    class="img-sort-icon">
                </span>
              </div>
            </th>
            <th>Fare</th>
            <th>Tip</th>
            <th>Trip Status</th>
            <th>Payment Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="myTable">
          <tr
            *ngFor="let data of trips | paginate: { id: 'server' ,itemsPerPage: pagination.limit, currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
            <td>{{index+i+1}}</td>
            <td>{{data.startTime | date }}</td>
            <td>{{data.driver? data.driver.firstName:'NA'}}</td>
            <td>{{data.rider? data.rider.firstName:'NA'}}</td>
            <td>{{data.fromAddress}}</td>
            <td>{{data.toAddress}} </td>
            <td>{{data.payment? data.payment.amountPayable: 'NA'}}</td>
            <td>{{data.payment? (data.payment.tip?data.payment.tip:'NA' ): 'NA'}}</td>
            <td>
              {{getMappedTripStatus(data.status, data.auctionId)}}
            </td>
            <td>{{data.payment? (data.payment.paid ? 'PAID' : 'PENDING'): 'NA'}}</td>
            <td>

              <button
                *ngIf="data.payment && data.payment.paid && data.payment.paidByChargeId && (data.payment.refund && !data.payment.refund.refunded)"
                class="btn btn-primary" (click)="refundPaymentDialoge(data,i)">Refund</button>
              <span *ngIf="data.payment && !data.payment.paid">
                Payment not Done
              </span>
              <span *ngIf="data.payment && data.payment.refund && data.payment.refund.refunded">
                Payment Refunded
              </span>
              <span *ngIf="!data.payment || !data.payment.paidByChargeId">
                NA
              </span>
            </td>
            <!-- <td>
              <i class="fa fa-eye fa-2x" aria-hidden="true" (click)="viewTripDetail(data)"></i>
            </td> -->
          </tr>
        </tbody>
      </table>

      <!-- for no data-->
      <div *ngIf="isApiResponded && trips.length <= 0" class="col-sm-10 text-center">
        <h2>No Trips Found</h2>
      </div>

      <!-- pagination -->
      <div class="col-md-10 text-center">
        <div class="has-text-centered">
          <pagination-controls [style.display]="(trips.length == 0) ? 'none' : 'block'"
            (pageChange)="pageChange($event)" id="server"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
<refund-payment #refundPayment (callbackRefunded)="refundedSuccess($event)"></refund-payment>