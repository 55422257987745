<div class="container-fluid">
    <h3>Feedbacks</h3>
    <div class="row">
        <div class="col-sm-12">
            <div class="table-responsive col-sm-12">
                <table class="table table-hover table-striped table-bordered">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>UserType</th>
                            <th>Date</th>
                            <th>Os Version</th>
                            <th>Os Type</th>
                            <th>appVersion</th>
                            <th>message</th>
                        </tr>
                    </thead>

                    <tbody id="myTable" *ngIf="isApiResponded && feedbackList.length > 0">
                        <tr *ngFor="let data of feedbackList | paginate: { id: 'server' ,itemsPerPage:pagination.limit , currentPage: pagination.currentPage , totalItems: pagination.total_count } ; let i=index ">
                            <td>{{index+i+1}}</td>
                            <td>{{data.name}} </td>
                            <td>{{data.phone}}</td>
                            <td>{{data.email?data.email:"NA"}}</td>
                            <td>{{data.userType}}</td>
                            <td>{{data.createdAt | date}}</td>
                            <td>{{data.osVersion}}</td>
                            <td>{{data.osType}}</td>
                            <td>{{data.appVersion}}</td>
                            <td>{{data.message}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col-md-10 text-center" *ngIf="isApiResponded && feedbackList.length > 0">
            <div class="has-text-centered">
                <pagination-controls (pageChange)="getFilterPromocodes($event)" id="server"></pagination-controls>
            </div>
        </div>

    </div>
</div>