<div class="vertical-menu-header">
    <img src="../../../assets/images/Logo-wapanda.png" alt="" width="120">
    <a routerLink="/">Wapanda Admin</a>
</div>
<div class="vertical-menu">
    <a routerLink="/" [routerLinkActive]="['router-link-active']" [routerLinkActiveOptions]="{exact:true}">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        </div>
        <span> Dashboard</span>

    </a>

    <a routerLink="/rider" [routerLinkActive]="['router-link-active']">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/rider.svg" alt="err" width="30" height="30"> </span>
        </div>
        <span>Riders</span>
    </a>

    <a routerLink="/driver" [routerLinkActive]="['router-link-active']">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/driver.svg" alt="err" width="30" height="30">
            </span>
        </div>
        <span>Drivers</span>
    </a>

    <a routerLink="/unverified-drivers" [routerLinkActive]="['router-link-active']">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/verification.svg" alt="err" width="30" height="30">
            </span>
        </div>
        <span>Verifications</span>

        <span *ngIf="unverifiedDocumentCount" class="badge">{{unverifiedDocumentCount}}</span>
    </a>

    <a routerLink="/manage-database" [routerLinkActive]="['router-link-active']">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/masterData.svg" alt="err" width="30" height="30">
            </span>
        </div>
        <span> Manage Database</span>
    </a>

    <a routerLink="/change-password" [routerLinkActive]="['router-link-active']">
        <div style="float:left;clear:right">
            <span class="img-icon">
                <img src="../../../assets/icons/change-password.svg" alt="err" width="30" height="30">
            </span>
        </div>
        <span> Change Password</span>

    </a>

    <a routerLink="/trackdrivers" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Active Drivers
    </a>
    <a routerLink="/messaging" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Messaging
    </a>
    <a routerLink="/promocodes" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Promocodes
    </a>
    <a routerLink="/flattrips" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        FlatTrips
    </a>
    <a routerLink="/events" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Events
    </a>
    <a routerLink="/trips" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Rides
    </a>

    <a routerLink="/feedbacks" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Feedbacks
    </a>
    <a routerLink="/stripe" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Stripe
    </a>
    <a routerLink="/schedulerides" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Scheduled Rides
    </a>
    <a routerLink="/ehailrequest" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Ehail requests
    </a>
    <a routerLink="/tlcmanager" [routerLinkActive]="['router-link-active']">
        <span class="img-icon">
            <img src="../../../assets/icons/dashboard.svg" alt="err" width="30" height="30"> </span>
        Tlc managers
    </a>

</div>