<div class="container-fluid">
  <div class="row">

    <div class="col-sm-12 form-body">

      <form class="form-horizontal" [formGroup]="form" (ngSubmit)="updateVehiclePrice()">
        <div class="col-sm-6 ">

          <div class="form-data">

            <div class="form-group">
              <label class="control-label col-sm-4">Vehicle Name:</label>
              <div class="col-sm-8">
                <label for="">{{vehicleTypeDetails.vehicleType}}</label>
               
                <!-- <input type="text" class="form-control" placeholder="Vehicle Name" formControlName="vehicleType" (focus)="vehicleHasfocus=true"
                  (blur)="vehicleHasfocus=false" [(ngModel)]="vehicleTypeDetails.vehicleType" /> -->

                <!-- <p *ngIf="!form.controls.vehicleType.value && !vehicleHasfocus && ((submittedOnce && !form.controls.vehicleType.valid)
                        || (!submittedOnce && form.controls.vehicleType.touched && !form.controls.vehicleType.valid))" class="text-left text-danger">Vehicle name is required</p> -->
              </div>
            </div>

            <div class="form-group">
              <label class="control-label col-sm-4">Default Price:</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" placeholder="Default Price" formControlName="price" (focus)="priceHasfocus=true"
                  (blur)="priceHasfocus=false" [(ngModel)]="vehicleTypeDetails.price" />

                <p *ngIf="!form.controls.price.value && !priceHasfocus && ((submittedOnce && !form.controls.price.valid)
                        || (!submittedOnce && form.controls.price.touched && !form.controls.price.valid))" class="text-left text-danger">
                  Price is required</p>

                <p *ngIf="form.controls.price.value && !priceHasfocus && ((submittedOnce && !form.controls.price.valid)
                        || (!submittedOnce && form.controls.price.touched && !form.controls.price.valid))" class="text-left text-danger">
                  Enter a valid price </p>
              </div>
            </div>

            <!-- <div class="form-group">
              <label class="control-label col-sm-4">Image Icon:</label>
              <div class="col-sm-8">
                <label class="btn btn-default btn-file">                      
                <input #uploadInput type="file" accept="image/x-png,image/gif,image/jpeg"  (change)="extractImage($event)" style="display: none;" /> Upload
              </label>
              <p *ngIf="!isUploadFileSelected" class="file-name">No file uploaded</p>
              <p *ngIf="isUploadFileSelected" class="file-name">{{uploadedFileName}}</p>              
                <p *ngIf="iconMissing" class="text-left text-danger">
                  Please upload image icon </p>
              </div>
            </div> -->

          </div>

          <div class="form-button">
            <div class="form-group">
              <div class="col-sm-10 ">
                <!--New div, offset because there is no label -->
                <button type="button" class="btn btn-primary" (click)="navigateToManageVehicleList()">Cancel</button>
                <button type="submit" class="btn btn-primary submit-button">Submit</button>
              </div>
            </div>
          </div>

        </div>

      </form>

    </div>

  </div>
</div>