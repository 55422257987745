<div class="container-fluid">
  <div class="row">

    <div *ngIf="isApiResponded && vehicleResponse.length > 0">

      <!-- New Document button -->
    <!--<div class="text-right">
        <button type="button" class="btn btn-primary add-type-button" (click)="createVehicleType()">Add</button>
      </div>-->

      <!-- Table -->
      <div class="table-responsive col-sm-12" style="margin-top: 16px">
        <table class="table table-hover table-striped table-bordered">
          <thead class="table-head">
            <tr>
              <th>#</th>
              <th>Vehicle Type</th>
              <th>Default Price(in dollars)</th>
              <th>Image</th>
              <th>Action</th> 
            </tr>
          </thead>
          <tbody id="myTable">
            <tr *ngFor="let data of vehicleResponse | paginate: { id: 'server' ,itemsPerPage: 10, currentPage: vehiclePagination.currentPage , totalItems: vehiclePagination.total_count } ; let i=index ">
              <td>{{index+i+1}}</td>
              <td>{{data.vehicleType}} </td>
              <td>{{data.price}}</td>
              <td><a href="{{getImagePath(data.icon._id)}}">{{data.icon.fileName}}</a>
              </td>
              <td>
                <i class="fa fa-pencil fa-2x" aria-hidden="true" [routerLink]="['../manage-vehicle/',data._id]"></i>
                <!-- <i *ngIf="data.isActive" class="fa fa-toggle-on fa-2x" aria-hidden="true" (click)="changeVehicleStatus(data,false)"></i> -->
                <!-- <i *ngIf="!data.isActive" class="fa fa-toggle-off fa-2x" aria-hidden="true" (click)="changeVehicleStatus(data,true)"></i> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- pagination -->
      <div class="col-sm-10 text-center">
        <div class="has-text-centered">
          <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
          <pagination-controls (pageChange)="getAllVehicles($event)" id="server"></pagination-controls>
        </div>
      </div>

    </div>

    <!-- for no data-->
    <div *ngIf="isApiResponded && vehicleResponse.length <= 0" class="col-sm-12 text-center">
      <span class="fa-stack fa-5x add-icon" [routerLink]="['../manage-vehicle/create']">
          <i class="fa fa-circle fa-stack-2x"></i>
          <i class="fa fa-plus fa-stack-1x fa-inverse"></i>
        </span>
      <h4>No new vehicle. Please add one.</h4>
    </div>

  </div>
</div>