<div class="container-fluid row">
    <div class="col-sm-2 left_col">
        <side-menu>
        </side-menu>
    </div>
    <div class="col-sm-2" style="text-align: right;float: right;margin-top:10px">
        <a class="btn btn-danger" (click)="logout()">Logout</a>
    </div>
    <h2>
        TLC dashboard
    </h2>
</div>