<div class="col-sm-12" style="margin-bottom: 10px">
    <h2>
        Ehail Refusals
    </h2>
    <form [formGroup]="refusaltForm" (submit)="onSubmit(refusaltForm)">
        <div class="col-sm-12">
            <label for="effectiveUpto" class="form-control-label">Select date </label>
        </div>
        <div class="">
            <div class="col-sm-3" style="display: table">
                <my-date-range-picker name="dateRange" [options]="myDatePickerOptions" formControlName="dateRange" (dateRangeChanged)="onDateRangeChanged($event)"></my-date-range-picker>
                <p *ngIf="(!refusaltForm.controls.dateRange.valid && refusaltForm.controls.dateRange.touched) || (!refusaltForm.controls.dateRange.valid && submittedOnce)"
                class="text-left text-danger">
                Date range is required</p>
            </div>
            <div class="col-sm-3">
                <button class="btn btn-primary">Ehail Refusal Export</button>
            </div>
        </div>
    </form>
</div>