<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12 remove-padding">


      <div class="col-sm-2 remove-padding">
        <!-- Document list -->
        <div class="col-sm-10 remove-padding document-list">
          <div class="list-group">
            <button *ngFor="let item of documentTypes;let i=index" type="button" class="list-group-item list-group-item-action" [ngClass]="{'active': selectedDocument == item}"
              (click)="getDocument(item)">{{item.type}}</button>
          </div>
        </div>
      </div>

      <!-- Editor -->
      <div class="col-sm-10 remove-padding">

        <!-- New Document button -->
        <div class="text-right">
          <button type="button" class="btn btn-primary newDocument-button" (click)="newDocument()">New Document</button>
        </div>

        <div class="editor">

          <!-- New Document text field -->
          <div class="text-right">
            <input type="text" class="form-control documentName-text" placeholder="Document Name" id="documentName" [(ngModel)]="type">
          </div>

          <!-- cke Editor -->
          <ckeditor [(ngModel)]="content" debounce="500">
          </ckeditor>

          <!-- save button -->
          <div class="text-right">
            <button type="button" class="btn btn-primary save-button" (click)="updateDocument()">Save</button>
          </div>

        </div>

      </div>

    </div>

    <p-confirmDialog></p-confirmDialog>

  </div>
</div>