<div class="">
    <h4> Search Location</h4>
    <div class="form-group">
        <input placeholder="search for location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control"
            #search (keyup)="searchAddress($event)">
    </div>
</div>
<div id="map"></div>
<div id="infowindow-content">
    <img src="" width="16" height="16" id="place-icon">
    <span id="place-name" class="title"></span>
    <br>
    <span id="place-address"></span>
</div>

<br>
<div #mapContainer class="map"></div>